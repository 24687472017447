import React from 'react';

export const XIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='21.506'
      height='21.506'
      viewBox='0 0 21.506 21.506'
      className={props.className}
    >
      <path
        id='x'
        d='M4001-3810.291l-9.9-9.9-9.9,9.9a.5.5,0,0,1-.707,0,.5.5,0,0,1,0-.707l9.9-9.9-9.9-9.9a.5.5,0,0,1,0-.707.5.5,0,0,1,.707,0l9.9,9.9,9.9-9.9a.5.5,0,0,1,.706,0,.5.5,0,0,1,0,.707l-9.9,9.9,9.9,9.9a.5.5,0,0,1,0,.707.5.5,0,0,1-.353.146A.5.5,0,0,1,4001-3810.291Z'
        transform='translate(-3980.35 3831.651)'
        fill='currentColor'
      />
    </svg>
  );
};
