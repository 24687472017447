/*************************************************  Imports  *************************************************/
import React, { useState } from 'react';
import styled from '@emotion/styled';

import { RouterPage } from '../RouterPage';
import { getColor } from '../../../assets/style/colors/colors';
import { getVar } from '../../../assets/style/variables/variables';
import { XP } from './data/skills.data';
import { SkillsModel } from './data/skills.model';

import * as animations from '../../../assets/style/animations';
import { getFont } from '../../../assets/style/fonts/fonts';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

/*************************************************  Component  *************************************************/
export const SkillsPage = () => {
  /*************************************************  Animation State  *************************************************/
  const [isLateAnimation, setIsLateAnimation] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (history.action !== 'POP') {
      setIsLateAnimation(true);
    }
  }, [history]);

  /*************************************************  Methods  *************************************************/
  function getClassNamesForTimelineElements(
    lateAnimation: boolean,
    isOdd: boolean
  ) {
    const classNameArray: string[] = [];

    if (lateAnimation) {
      classNameArray.push('lateAnimation');
    }
    if (isOdd) {
      classNameArray.push('odd');
    }

    return classNameArray.join(' ');
  }

  function renderTimelineItem(item: SkillsModel, index: number) {
    return (
      <TimelineItem key={index} className={index % 2 !== 0 ? 'odd' : ''}>
        <TimelineDateContainer
          className={getClassNamesForTimelineElements(
            isLateAnimation,
            index % 2 !== 0
          )}
        >
          <div>
            <p>
              {item.dateTo}
              <TimelineDateSeparator>-</TimelineDateSeparator>
            </p>
            <p>{item.dateFrom}</p>
          </div>
        </TimelineDateContainer>
        <TimelineContent
          className={getClassNamesForTimelineElements(
            isLateAnimation,
            index % 2 !== 0
          )}
        >
          <div>
            <XpPosition>{item.position}</XpPosition>
            <XpCompany href={item.company.link}>{item.company.name}</XpCompany>

            <XpSkillsContainer>
              {item.skills.map((skill) => {
                return <XpSkill key={skill}>- {skill}</XpSkill>;
              })}
            </XpSkillsContainer>

            <XpOtherContainer>
              {item.other?.map((other) => {
                return (
                  <XpOther key={other.text} href={other.link}>
                    {other.text}
                  </XpOther>
                );
              })}
            </XpOtherContainer>
          </div>
        </TimelineContent>
      </TimelineItem>
    );
  }

  /*************************************************  Template  *************************************************/
  return (
    <StyledRouterPage>
      <PageHeader className={isLateAnimation ? 'lateAnimation' : ''}>
        <PageTitle>Skills & Experience</PageTitle>
      </PageHeader>

      <TimelineContainer className={isLateAnimation ? 'lateAnimation' : ''}>
        {XP.map((value, index) => {
          return renderTimelineItem(value, index);
        })}
      </TimelineContainer>

      <PageFooter
        className={isLateAnimation ? 'lateAnimation' : ''}
      ></PageFooter>
    </StyledRouterPage>
  );
};

/*************************************************  Style  *************************************************/
const StyledRouterPage = styled(RouterPage)`
  background: ${getColor('section')};
  padding: 0 ${getVar('appSpacing')};
`;

const PageHeader = styled.div`
  width: 100%;
  position: relative;
  height: 18rem;

  &.lateAnimation {
    &::before {
      animation: ${animations.height100} 0.5s 3.5s linear forwards;
    }

    &::after {
      animation: ${animations.timelineHorizontal} 0.5s 4s ease-out forwards;
    }
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    width: 0.1rem;
    background-color: ${getColor('text')};
    animation: ${animations.height100} 0.5s 0.5s linear forwards;
  }

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    right: 50%;
    width: 0;
    height: 0.1rem;
    background-color: ${getColor('transparent')};
    animation: ${animations.timelineHorizontal} 0.5s 1s ease-out forwards;
  }

  @media (max-width: 855px) {
    &::after {
      background-color: ${getColor('text')};
    }
  }
`;

const PageTitle = styled.span`
  font-family: ${getFont('heading')};
  font-size: 2.5rem;
  color: ${getColor('heading')};
  position: absolute;
  top: 6rem;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: 450px) {
    text-align: center;
  }
`;

const TimelineContainer = styled.div`
  position: relative;
  min-height: calc(100% - 36rem);

  &.lateAnimation {
    &::before {
      animation: ${animations.height100} 1.5s 4s linear forwards;
    }
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    width: 0.1rem;
    background-color: ${getColor('text')};
    animation: ${animations.height100} 1.5s 1s linear forwards;
  }

  @media (max-width: 855px) {
    &.lateAnimation {
      &::before {
        animation: ${animations.height100} 1.5s 4.5s linear forwards;
      }
    }

    &::before {
      transform: unset;
      left: 0;
      animation: ${animations.height100} 1.5s 1.5s linear forwards;
    }
  }
`;

const TimelineItem = styled.div`
  width: 100%;
  padding: 6rem 0;
  display: flex;
  justify-content: center;

  &.odd {
    flex-direction: row-reverse;
  }

  @media (max-width: 855px) {
    && {
      flex-direction: column;
    }
  }
`;

const TimelineDateContainer = styled.div`
  width: 50%;
  padding-right: 6.5rem;
  position: relative;
  height: 7rem;
  font-size: 1.6rem;
  color: ${getColor('accent')};
  line-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  opacity: 0;
  animation: ${animations.slideInLeftSkill} 0.5s 1.5s ease-out forwards;

  &.lateAnimation {
    animation: ${animations.slideInLeftSkill} 0.5s 4.5s ease-out forwards;

    &.odd {
      animation: ${animations.slideInRightSkill} 0.5s 4.5s ease-out forwards;
    }
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    right: -0.2rem;
    width: 0.4rem;
    height: 100%;
    background-color: ${getColor('accent')};
  }

  &.odd {
    justify-content: flex-start;
    text-align: left;
    padding-right: 0;
    padding-left: 6.5rem;
    animation: ${animations.slideInRightSkill} 0.5s 1.5s ease-out forwards;

    &::before {
      right: unset;
      left: -0.2rem;
    }
  }

  @media (max-width: 855px) {
    && {
      width: 100%;
      justify-content: flex-start;
      text-align: left;
      padding-right: 0;
      padding-left: 6.5rem;
      margin-bottom: 3rem;
      animation: ${animations.slideInRightSkill} 0.5s 2s ease-out forwards;

      &.lateAnimation {
        animation: ${animations.slideInRightSkill} 0.5s 5s ease-out forwards;
      }

      &::before {
        right: unset;
        left: -0.2rem;
      }
    }
  }

  @media (max-width: 499px) {
    && {
      padding-left: 3rem;
    }
  }
`;

const TimelineDateSeparator = styled.span`
  margin-left: 1.5rem;
`;

const TimelineContent = styled.div`
  width: 50%;
  padding-left: 6.5rem;
  display: flex;
  align-items: center;
  opacity: 0;
  animation: ${animations.slideInRightSkill} 0.5s 1.5s ease-out forwards;

  &.lateAnimation {
    animation: ${animations.slideInRightSkill} 0.5s 4.5s ease-out forwards;

    &.odd {
      animation: ${animations.slideInLeftSkill} 0.5s 4.5s ease-out forwards;
    }
  }

  &.odd {
    justify-content: flex-end;
    padding-left: unset;
    padding-right: 6.5rem;
    text-align: right;
    animation: ${animations.slideInLeftSkill} 0.5s 1.5s ease-out forwards;
  }

  @media (max-width: 855px) {
    && {
      width: 100%;
      justify-content: flex-start;
      text-align: left;
      padding-right: 0;
      padding-left: 6.5rem;
      animation: ${animations.slideInRightSkill} 0.5s 2s ease-out forwards;

      &.lateAnimation {
        animation: ${animations.slideInRightSkill} 0.5s 5s ease-out forwards;
      }
    }
  }

  @media (max-width: 499px) {
    && {
      padding-left: 3rem;
    }
  }
`;

const XpPosition = styled.p`
  font-size: 2.6rem;
  color: ${getColor('heading')};

  @media (max-width: 499px) {
    font-size: 2.4rem;
  }
`;

const XpCompany = styled.a`
  font-size: 2.1rem;
  color: ${getColor('text')};

  &[href]:hover {
    color: ${getColor('accent')};
  }

  @media (max-width: 499px) {
    font-size: 1.9rem;
  }
`;

const XpSkillsContainer = styled.div`
  margin-top: 2.5rem;
`;

const XpSkill = styled.p`
  font-size: 1.6rem;
  white-space: break-spaces;
  margin-bottom: 0.5rem;
  line-height: 2.5rem;

  @media (max-width: 499px) {
    font-size: 1.4rem;
  }
`;

const XpOtherContainer = styled.div`
  margin-top: 3.5rem;
`;

const XpOther = styled.a`
  display: block;
  font-size: 1.6rem;
  color: ${getColor('text')};
  margin-bottom: 0.5rem;
  line-height: 2.5rem;
  position: relative;

  &[href] {
    &::after {
      position: absolute;
      content: '➟';
      color: ${getColor('accent')};
      margin-left: 1rem;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 499px) {
    font-size: 1.4rem;
  }
`;

const PageFooter = styled.div`
  width: 100%;
  position: relative;
  height: 18rem;

  &.lateAnimation {
    &::before {
      animation: ${animations.height100} 0.5s 5.5s linear forwards;
    }

    &::after {
      animation: ${animations.timelineHorizontal} 0.5s 6s ease-out forwards;
    }
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 0;
    width: 0.1rem;
    background-color: ${getColor('text')};
    animation: ${animations.height100} 0.5s 2.5s linear forwards;
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 0;
    height: 0.1rem;
    background-color: ${getColor('transparent')};
    animation: ${animations.timelineHorizontal} 0.5s 3s ease-out forwards;
  }

  @media (max-width: 855px) {
    &::before {
      animation: ${animations.height100} 0.5s 3.5s linear forwards;
    }

    &::after {
      background-color: ${getColor('text')};
    }

    &.lateAnimation {
      &::before {
        animation: ${animations.height100} 0.5s 6.5s linear forwards;
      }
    }
  }
`;
