/*************************************************  Imports  *************************************************/
import React, { useEffect, useRef } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import styled from '@emotion/styled';

import { getColor } from './assets/style/colors/colors';

import { Sidebar } from './core/components/Sidebar/Sidebar';
import { Loader } from './core/components/Loader';
import { NotFoundPage } from './core/components/NotFoundScreen/NotFoundPage';
import { HomePage } from './core/components/HomeScreen/HomePage';
import { SkillsPage } from './core/components/SkillsScreen/SkillsPage';
import { CssPage } from './core/components/CssScreen/CssPage';
import { NotesPage } from './core/components/CssScreen/NotesScreen/NotesPage';
import { LinksPage } from './core/components/CssScreen/LinksScreen/LinksPage';
import { BlogPage } from './core/components/CssScreen/BlogScreen/BlogPage';
import { CustomCursor } from './core/components/CustomStuff/CustomCursor';
import { BlogPost } from './core/components/CssScreen/BlogScreen/posts/BlogPost';

/*************************************************  Component  *************************************************/
export default function App() {
  /*************************************************  Hooks  *************************************************/
  const location = useLocation();
  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;

      // Custom console log on first page load
      console.log(
        '%cJoakim Zebic ➟ CSS Wizard',
        'background-color:#2c2f33;background-image: url(https://lh3.googleusercontent.com/E59VfnL41nLjzUjIEPgpD3kFbXK8W3xRHo4GYZ91pqlVyJTmFPA_z820cNyscFZvgz3QmAVWHYo9IUR6KRu53n9qdFpfPF1E9dOCb4gzgKZoOwJ5RBwhqRtt2qMSoqXstCFCyegNBHjk4IavbE-n6jTSLLcAc03YXRx2NP66bREghlvpT7dqMN2u2ykXOKx9GzTubKZ1MQn4O-ZaVkIfJ2JVoQ3_c1C0K5XWoKcibRDt1yq8MKM7tL6NQFKDfyTF_VX4u3QflGT1QKRRor01kVBTBK15nFbVCTIfA3YlizbvNklLedNcdvbC3Z-HIj1tpd-55uh3A1_9tVFDByl3JVg35kkB7mLzFLoLtDZptsF763B1O6_rFVx2JY907DPi-sZ7kwLzSJ_HOm2jlQvUh1RQt_QdtbZmOKVFqHuhsCrkWwxe5ANRRZS96iIfA6oNOZMzk84g3DjhuUZlw37jxvwVAM-RSzA9ydoc96ixejlvH7lUQD3pxzL-vQiyjfaNFsDgckDdZ9WszyhvpJVqQw6rNNEYPTQatlON2lK9Cm8FPfNK73QdttWVVxRhFQDSzvtjwU84Uchre5nMsGbWr4HPMFw8cpM2Tgo-aMWtv_zL329tXAHzxY-TK5Jqd5oo6dGS1xXowxvEgKUBc4BU1AzM6c7LZMj2zL0nD9JwqYQflHtJQHK-W1ZofeDO=s216-no?authuser=0);background-repeat:no-repeat;background-size: 5rem 5rem; background-position: 5rem 50%;padding: 5rem 15rem; color: #cecece; font-family: Montserrat; font-size: 1.5rem; font-weight: 200;'
      );
      console.log(
        '%cHi there fellow developer  :D',
        'background-color:#2c2f33;padding: 5rem 15rem; color: #cecece; font-family: Montserrat; font-size: 1.5rem; font-weight: 200;'
      );
      // End of custom console log
    } else if (location.pathname && !location.hash) {
      showLoader();
    }
  }, [location]);

  /*************************************************  Functions  *************************************************/
  //! For restarting the animation if user clicks on multiple links
  //! It is not enough to just remove the class and then apply it again -- this will only continue animation where it left off
  //! That is why we have two identical animation (with different names -- e.g: anim1 , anim2) applied on different classes (show1, show2)
  //! And then we toggle between them
  function showLoader() {
    const loader = document.querySelector('.loader');
    if (loader?.classList.contains('show1')) {
      loader?.classList.remove('show1');
      loader?.classList.add('show2');
    } else {
      loader?.classList.remove('show2');
      loader?.classList.add('show1');
    }
  }

  /*************************************************  Template  *************************************************/
  return (
    <MainWrap>
      <CustomCursor />
      <Sidebar></Sidebar>

      <PageContainer>
        <Route
          render={({ location }) => (
            <TransitionGroup>
              <CSSTransition
                key={location.key}
                timeout={3000}
                classNames='load'
              >
                <Switch location={location}>
                  <Route path='/' exact>
                    <HomePage />
                  </Route>
                  <Route path='/skills'>
                    <SkillsPage />
                  </Route>
                  <Route path='/css' exact>
                    <CssPage />
                  </Route>
                  <Route path='/css/notes'>
                    <NotesPage />
                  </Route>
                  <Route path='/css/links'>
                    <LinksPage />
                  </Route>
                  <Route path='/css/blog' exact>
                    <BlogPage />
                  </Route>
                  <Route path='/css/blog/*'>
                    <BlogPost />
                  </Route>
                  <Route path='*'>
                    <NotFoundPage />
                  </Route>
                </Switch>
              </CSSTransition>
            </TransitionGroup>
          )}
        ></Route>
        <Loader />
      </PageContainer>
    </MainWrap>
  );
}

/*************************************************  Styles  *************************************************/
const MainWrap = styled.div`
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;
  background: ${getColor('gradientLight')};
`;

const PageContainer = styled.div`
  width: 100%;
`;
