import React from 'react';

export const AngularIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='27.916'
      height='30'
      viewBox='0 0 27.916 30'
      className={props.className}
    >
      <path
        id='angular'
        d='M17.118,2.5,31.076,7.474,28.96,25.947,17.118,32.5,5.276,25.947,3.16,7.474,17.118,2.5m0,3.316L8.386,25.395h3.253l1.753-4.389h7.421l1.753,4.389H25.8L17.118,5.816m2.558,12.474h-5.1l2.542-6.111Z'
        transform='translate(-3.16 -2.5)'
        fill='currentColor'
      />
    </svg>
  );
};
