import React from 'react';
import { RouterPage } from '../RouterPage';
import styled from '@emotion/styled';
import { NotFoundIcon } from '../../../assets/icon-components/NotFoundIcon';
import { getColor } from '../../../assets/style/colors/colors';
import { getVar } from '../../../assets/style/variables/variables';

export const NotFoundPage = () => {
  return (
    <StyledRouterPage>
      <NFContainer>
        <StyledNotFoundIcon />
        <StyledSpan>Oops.. No magic here :/</StyledSpan>
      </NFContainer>
    </StyledRouterPage>
  );
};

const StyledRouterPage = styled(RouterPage)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${getColor('section')};
`;

const NFContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const StyledNotFoundIcon = styled(NotFoundIcon)`
  max-width: 40rem;
  width: 45vw;
  height: auto;
  margin-bottom: ${getVar('appSpacing')};
  color: ${getColor('accent')};
  transition: color 0.5s;
`;

const StyledSpan = styled.span`
  text-align: center;
  font-size: 2.5rem;
`;
