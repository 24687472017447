import React from 'react';

export const HTMLIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='24.492'
      height='27.797'
      viewBox='0 0 24.492 27.797'
      className={props.className}
    >
      <path
        id='html'
        d='M16.316,25.484,22.6,23.739l.849-9.42H12.27l-.278-3.135H23.728l.309-3.073H8.595l.865,9.281H20.1l-.355,3.984-3.428.927-3.428-.927-.216-2.563H9.583l.448,4.926,6.285,1.745M4.07,3H28.562L26.354,28.017,16.316,30.8,6.278,28.017Z'
        transform='translate(-4.07 -3)'
        fill='currentColor'
      />
    </svg>
  );
};
