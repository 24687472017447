/*************************************************  Imports  *************************************************/
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import { getVar } from '../../../assets/style/variables/variables';
import { getColor } from '../../../assets/style/colors/colors';

import { RouterPage } from '../RouterPage';
import { SectionUpIcon } from '../../../assets/icon-components/SectionUpIcon';
import { TriangleIcon } from '../../../assets/icon-components/TriangleIcon';

import jz from '../../../assets/images/JZ.png';
import * as animations from '../../../assets/style/animations';
import { getFont } from '../../../assets/style/fonts/fonts';
import { NavLink, useHistory } from 'react-router-dom';

/*************************************************  Component  *************************************************/
export const HomePage = () => {
  /*************************************************  Animation State  *************************************************/
  const [isLateAnimation, setIsLateAnimation] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (history.action !== 'POP') {
      setIsLateAnimation(true);
    }
  }, [history]);

  /*************************************************  Template  *************************************************/
  return (
    <StyledRouterPage>
      <TextContainer>
        <StyledHeading className={isLateAnimation ? 'lateAnimation' : ''}>
          <OverlineLetter>H</OverlineLetter>i, I'm Joakim
        </StyledHeading>

        <AboutContainer className={isLateAnimation ? 'lateAnimation' : ''}>
          <MainText>
            Frontend developer and CSS Wizard from Serbia. Code and design are
            my two passions - so being a frontend developer allows me to combine
            them and to enjoy every day of my job.
          </MainText>

          <FunText>
            <p>Perfectionist powered by CocaCola</p>
            <p>Human since 1998</p>
            <p>Casting CSSpells since 2017</p>
          </FunText>
        </AboutContainer>
      </TextContainer>

      <ImageContainer className={isLateAnimation ? 'lateAnimation' : ''}>
        <StyledImage
          src={jz}
          alt='Joakim Zebic'
          className={isLateAnimation ? 'lateAnimation' : ''}
        />
      </ImageContainer>

      <SectionContainer className={isLateAnimation ? 'lateAnimation' : ''}>
        <Line className={isLateAnimation ? 'lateAnimation' : ''}>
          <NavLink to='/skills'>
            <TriangleIcon />
          </NavLink>
        </Line>

        <SectionUpIcon />
      </SectionContainer>
    </StyledRouterPage>
  );
};

/*************************************************  Style  *************************************************/
const StyledRouterPage = styled(RouterPage)`
  background: linear-gradient(
    to bottom right,
    ${getColor('gradientDark')},
    ${getColor('gradientLight')}
  );

  @media (max-height: 799px) and (max-width: 855px) {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
`;

const TextContainer = styled.div`
  margin: 14rem 0 0 12rem;
  max-width: 62rem;
  position: relative;
  z-index: 3;

  @media (max-width: 1450px) {
    margin: 14rem 0 0 6rem;
  }

  @media (max-width: 1349px) {
    max-width: 52rem;
  }

  @media (max-width: 1099px) {
    margin: 23rem 6rem 0 6rem;
    width: calc(100% - 12rem);
    max-width: 70rem;
  }

  @media (max-width: 855px) {
    margin: 26rem 6rem 0 6rem;
  }

  @media (max-width: 701px), (max-height: 799px) and (max-width: 900px) {
    margin: 30rem 6rem 0 6rem;
  }

  @media (max-width: 701px) {
    margin: 35rem 6rem 0 6rem;
  }

  @media (max-width: 500px) {
    margin: 35rem 3rem 0 3rem;
    width: calc(100% - 6rem);
  }

  @media (min-width: 856px) and (max-height: 660px) {
    margin: 6rem;
    max-width: 50rem;
  }

  @media screen and (width: 600px) and (height: 1024px) {
    margin-top: 45rem;
  }
`;

const StyledHeading = styled.h1`
  font-family: ${getFont('heading')};
  color: ${getColor('heading')};
  font-size: 6rem;
  font-weight: normal;
  opacity: 0;
  animation: ${animations.slideInLeftHeading} 0.5s 2.5s ease-out forwards;

  &.lateAnimation {
    animation: ${animations.slideInLeftHeading} 0.5s 5.5s ease-out forwards;
  }

  @media (max-width: 1349px) {
    font-size: 5rem;
  }

  @media (max-width: 500px) {
    font-size: 4rem;
  }

  @media (min-width: 265px) and (max-width: 300px) {
    font-size: 3.5rem;
  }

  @media (min-width: 100px) and (max-width: 264px) {
    font-size: 3rem;
  }

  @media (min-width: 856px) and (max-height: 660px) {
    font-size: 4rem;
  }
`;

const OverlineLetter = styled.span`
  position: relative;

  &::before {
    position: absolute;
    content: '';
    top: -1rem;
    left: 0;
    width: 100%;
    height: 0.2rem;
    background: ${getColor('accent')};
  }
`;

const AboutContainer = styled.div`
  margin: 10rem 0 0 7.5rem;
  line-height: 3rem;
  opacity: 0;
  animation: ${animations.scaleInTopText} 0.5s 2.5s ease-out forwards;

  &.lateAnimation {
    animation: ${animations.scaleInTopText} 0.5s 5.5s ease-out forwards;
  }

  @media (max-width: 1349px) {
    margin: 7rem 0 0 3rem;
  }

  @media (max-width: 701px) {
    margin: 5rem 0 0 3rem;
  }

  @media (max-width: 585px) {
    margin: 4rem 0 0 3rem;
  }

  @media (max-width: 500px) {
    margin: 4rem 0 0 2rem;
  }

  @media (min-width: 856px) and (max-height: 660px) {
    margin: 4rem 0 0 7.5rem;
  }
`;

const MainText = styled.p`
  font-size: 2rem;

  @media (max-width: 1349px) {
    font-size: 1.8rem;
  }

  @media (max-width: 500px) {
    font-size: 1.6rem;
  }

  @media (min-width: 856px) and (max-height: 660px) {
    font-size: 1.6rem;
  }
`;

const FunText = styled.div`
  margin-top: 3rem;
  font-size: 1.5rem;

  @media (max-width: 701px), (max-height: 799px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  height: calc(100% - (${getVar('appSpacing')} * 2));
  max-height: 82rem;
  max-width: 61.5rem;
  top: ${getVar('appSpacing')};
  right: ${getVar('appSpacing')};
  pointer-events: none;
  z-index: 2;

  &.lateAnimation {
    &::before {
      animation: ${animations.slideInRightLine} 0.5s 5s ease-out forwards;
    }
  }

  &::before {
    position: absolute;
    content: '';
    width: 13.5rem;
    height: 0.1rem;
    top: 50%;
    left: -13.5rem;
    transform: translateY(-50%);
    background-color: ${getColor('text')};
    opacity: 0;
    animation: ${animations.slideInRightLine} 0.5s 2s ease-out forwards;
  }

  @media (max-width: 1700px) {
    &::before {
      left: -5.5rem;
    }
  }

  @media (max-width: 1600px) {
    &::before {
      display: none;
    }
  }

  @media (max-width: 1450px) {
    height: calc(80% - (${getVar('appSpacing')} * 2));
    top: calc(10% + ${getVar('appSpacing')});
    max-width: 42.5rem;
  }

  @media (max-width: 1099px) {
    height: 47rem;
    top: 0;
    max-width: 35.5rem;
  }

  @media (max-width: 701px) {
    height: 50%;
    min-height: 36rem;
    right: 50%;
    transform: translateX(50%);
    max-width: 32.5rem;
  }
`;

const StyledImage = styled.img`
  height: 100%;
  opacity: 0;
  user-select: none;
  animation: ${animations.fadeInFwd} 1s 2s ease-out forwards;

  &.lateAnimation {
    animation: ${animations.fadeInFwd} 1s 5s ease-out forwards;
  }
`;

const Line = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0.1rem;
  height: ${getVar('lineHeight')};
  background-color: ${getColor('text')};
  z-index: 2;
  opacity: 0;
  animation: ${animations.slideInTop} 1s 1.5s ease-out forwards;

  &.lateAnimation {
    animation: ${animations.slideInTop} 1s 4.5s ease-out forwards;

    & > a {
      animation: ${animations.slideInTopAbsolute} 1s 4.5s ease-out forwards;
    }
  }

  & > a {
    position: absolute;
    top: 3rem;
    left: 50%;
    transform: translateX(-50%);
    color: ${getColor('accent')};
    width: 4rem;
    height: 3.5rem;
    opacity: 0;
    animation: ${animations.slideInTopAbsolute} 1s 1.5s ease-out forwards;
    transition: 0.5s;

    @media (hover: hover) {
      &:hover {
        width: 5rem;
        height: 4.5rem;
      }
    }
  }

  @media (max-height: 855px) {
    & > a {
      width: 2.8rem;
      height: 2.5rem;

      & > svg {
        width: 2.8rem;
        height: 2.5rem;
      }
    }
  }
`;

const SectionContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  color: ${getColor('section')};
  opacity: 0;
  animation: ${animations.slideInLeft} 1s 0.5s ease-out forwards;

  &.lateAnimation {
    animation: ${animations.slideInLeft} 1s 3.5s ease-out forwards;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1rem;
    background-color: ${getColor('section')};
  }

  @media (max-height: 799px) and (max-width: 855px) {
    position: relative;
    min-height: ${getVar('lineHeight')};
    display: flex;
    flex-direction: column-reverse;
    margin-top: ${getVar('appSpacing')};

    &::before {
      display: none;
    }
  }
`;
