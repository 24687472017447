export const darkTheme = {
  accent: '#ccff33',
  gradientDark: '#1f2227',
  gradientLight: '#565b61',
  nav: '#3c434b',
  navActive: '#575e66',
  heading: '#cecece',
  text: '#9b9b9b',
  section: '#2c2f33',
  shadow: '#000000',
};

export const lightTheme = {
  accent: '#0383c5',
  gradientDark: '#ffffff',
  gradientLight: '#d2d2d2',
  nav: '#d2d4da',
  navActive: '#bcbdc2',
  heading: '#333333',
  text: '#5d5d5d',
  section: '#f2f2f2',
  shadow: '#a4a4a4',
};
