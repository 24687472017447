import React from 'react';

export const SectionUpIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 37.503'>
      <path
        id='section-up'
        data-name='Path 2'
        d='M0,861.523,320,894.2v4.822H0Z'
        transform='translate(0 -861.523)'
        fill='currentColor'
      />
    </svg>
  );
};
