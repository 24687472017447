/*************************************************  Imports  *************************************************/
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { NavLink, useHistory } from 'react-router-dom';

import { getColor } from '../../../assets/style/colors/colors';
import { getVar } from '../../../assets/style/variables/variables';

import { RouterPage } from '../RouterPage';
import { SectionDownIcon } from '../../../assets/icon-components/SectionDownIcon';

import * as animations from '../../../assets/style/animations';
import { getFont } from '../../../assets/style/fonts/fonts';

/*************************************************  Component  *************************************************/
export const CssPage = () => {
  /*************************************************  Animation State  *************************************************/
  const [isLateAnimation, setIsLateAnimation] = useState(false);

  const history = useHistory();

  useEffect(() => {
    if (history.action !== 'POP') {
      setIsLateAnimation(true);
    }
  }, [history]);
  /*************************************************  Template  *************************************************/
  return (
    <StyledRouterPage>
      <PageHeader className={isLateAnimation ? 'lateAnimation' : ''}>
        <PageTitle>CSS</PageTitle>

        <SectionHolder className={isLateAnimation ? 'lateAnimation' : ''}>
          <SectionDownIcon />
        </SectionHolder>
      </PageHeader>

      <PageContent className={isLateAnimation ? 'lateAnimation' : ''}>
        <BoxContainer
          className={
            isLateAnimation ? 'lateAnimation disableSmall' : 'disableSmall'
          }
        >
          <Box to='/css/notes'>
            <BoxHeader>Notes</BoxHeader>
            <BoxContent>
              Helpful notes about various CSS stuff that I have encountered and
              thought it might be good to jot down for future reference.
            </BoxContent>
          </Box>
          <Box to='/css/links'>
            <BoxHeader>Links</BoxHeader>
            <BoxContent>
              Links to cool and helpful stuff about web development in general.
              {/* ( However it is mostly articles from CSS-Tricks because I live on
              that website ). */}
            </BoxContent>
          </Box>
        </BoxContainer>
        <BoxContainer
          className={isLateAnimation ? 'lateAnimation disableSmall' : ''}
        >
          <Box to='/css/blog'>
            <BoxHeader>Blog</BoxHeader>
            <BoxContent>
              Articles that I wrote about... well stuff...
            </BoxContent>
          </Box>
          <BoxDownload href='_spellbook.css' download>
            <BoxHeader>Spellbook</BoxHeader>
            <BoxContent>
              Downloadable CSS file that I use on personal projects. I kinda
              dislike Bootstrap - so I wrote my own file with classes to keep my
              CSS as 'DRY' ( Don't Repeat Yourself ) as possible.
              <br />
              It's always work in progress..
              <br />
              <br />
              _spellbook.css
            </BoxContent>
          </BoxDownload>
        </BoxContainer>
      </PageContent>
    </StyledRouterPage>
  );
};

/*************************************************  Style  *************************************************/
const StyledRouterPage = styled(RouterPage)`
  background: linear-gradient(
    to top right,
    ${getColor('gradientDark')},
    ${getColor('gradientLight')}
  );
  padding: 0;
`;

const PageHeader = styled.div`
  position: relative;
  width: 100%;
  height: 22rem;

  &.lateAnimation {
    &::before {
      animation: ${animations.height100} 0.5s 4s ease-out forwards;
    }

    &::after {
      animation: ${animations.cssAccentLine} 0.5s 4.5s ease-out forwards;
    }
  }

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0.1rem;
    height: 0;
    animation: ${animations.height100} 0.5s 1.5s ease-out forwards;
    background-color: ${getColor('text')};
    z-index: 1;
  }

  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scale(0);
    transform-origin: center;
    animation: ${animations.cssAccentLine} 0.5s 2s ease-out forwards;
    width: 17rem;
    height: 0.2rem;
    background-color: ${getColor('accent')};
    z-index: 1;
  }
`;

const PageTitle = styled.span`
  font-family: ${getFont('heading')};
  font-size: 2.5rem;
  color: ${getColor('heading')};
  position: absolute;
  top: 6rem;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const SectionHolder = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0;
  color: ${getColor('section')};
  animation: ${animations.slideInRight} 1s 0.5s ease-out forwards;

  &.lateAnimation {
    animation: ${animations.slideInRight} 1s 3s ease-out forwards;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 1rem;
    background-color: ${getColor('section')};
  }
`;

const PageContent = styled.div`
  position: relative;
  width: calc(100% - (${getVar('appSpacing')} * 2));
  max-width: 165rem;
  margin: 0 auto;
  min-height: calc(100% - 22rem);
  padding: calc(${getVar('appSpacing')} - 0.5rem) 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
`;

const BoxContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 80rem;
  opacity: 0;
  animation: ${animations.slideInRightSkill} 0.5s 2s ease-out forwards;

  &:first-of-type {
    animation: ${animations.slideInLeftSkill} 0.5s 2s ease-out forwards;
  }

  &.lateAnimation {
    animation: ${animations.slideInRightSkill} 0.5s 5s ease-out forwards;

    &:first-of-type {
      animation: ${animations.slideInLeftSkill} 0.5s 5s ease-out forwards;
    }
  }

  @media (max-width: 450px) and (max-height: 700px) {
    width: 100%;
  }

  @media (max-width: 319px) {
    &.disableSmall {
      display: none;
    }
  }
`;

const Box = styled(NavLink)`
  width: 100%;
  max-width: 35rem;
  height: 50rem;
  border: 0.1rem solid ${getColor('text')};
  color: ${getColor('text')};
  padding: 3rem;
  margin: 2.5rem;

  @media (hover: hover) {
    border-color: ${getColor('text', 0.5)};
    color: ${getColor('text', 0.5)};
    transition: 0.5s;

    &:hover {
      border-color: ${getColor('text', 1)};
      color: ${getColor('text', 1)};
    }
  }

  @media (max-width: 450px) and (max-height: 700px) {
    height: auto;
    min-width: 100%;
  }
`;

const BoxDownload = styled.a`
  width: 100%;
  max-width: 35rem;
  height: 50rem;
  border: 0.1rem solid ${getColor('text')};
  color: ${getColor('text')};
  padding: 3rem;
  margin: 2.5rem;

  @media (hover: hover) {
    border-color: ${getColor('text', 0.5)};
    color: ${getColor('text', 0.5)};
    background-color: ${getColor('accent', 0)};
    transition: 0.5s;

    &:hover {
      border-color: ${getColor('accent', 0.5)};
      background-color: ${getColor('accent', 0.05)};
      color: ${getColor('text', 1)};
    }
  }

  @media (max-width: 450px) and (max-height: 700px) {
    height: auto;
    min-width: 100%;
  }
`;

const BoxHeader = styled.p`
  font-family: ${getFont('heading')};
  width: 100%;
  font-size: 4rem;
  margin-bottom: 5rem;

  @media (max-width: 450px) and (max-height: 700px) {
    margin: 0;
  }

  @media (max-width: 300px) {
    font-size: 3rem;
  }
`;

const BoxContent = styled.p`
  width: 100%;
  word-break: break-word;
  font-size: 1.8rem;
  line-height: 3rem;

  @media (max-width: 450px) and (max-height: 700px) {
    display: none;
  }
`;
