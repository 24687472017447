import { CSSCardModel } from '../../CSSCard/css-card.model';

export const cssNotes: CSSCardModel[] = [
  {
    title: 'HTML <option> tag styling',
    content: {
      __html: `There is no way to make default &lt;option&gt; tag pretty. Only CSS that will take effect is color, background-color, and font related stuff.<br/><br/>Also, you can't set width / max-width to it.. It will always take as much space as the longest option ( !important; won't work ).<br/><br/>If you want to make nice looking dropdown, use custom solution or make one from scratch.`,
    },
    filters: ['html'],
  },
  {
    title: 'CSS Line Clamping',
    content: {
      __html: `Using 'ellipsis' -- single line:<br/><br/><code>text-overflow: ellipsis;<br/>overflow: hidden;<br/>white-space: nowrap;</code><br/><br/>Using 'line-clamp' -- multiline:<br/><br/><code>overflow: hidden;<br/>display: -webkit-box;<br/>-webkit-box-orient: vertical;<br/>-webkit-line-clamp: 2;</code><br/><br/><br/><a class='link' href='https://caniuse.com/#search=line-clamp'>Link: Can I Use</a>`,
    },
    filters: ['important', 'css'],
  },
  {
    title: 'Sticky hover state on mobile',
    content: {
      __html: `This resolves the 'sticky' hover state on mobile devices (on click).<br/><br/><code>@media and (hover: hover)</code><br/><br/>It's counterpart is<br/><br/><code>@media and (hover: none)</code><br/><br/><a class='link' href='https://caniuse.com/#search=media%20hover'>Link: Can I Use</a>`,
    },
    filters: ['important', 'mq', 'css'],
  },
  {
    title: 'Elements without pseudo-elements',
    content: {
      __html: `These elements don't support '::before' and '::after' pseudo-elements:<br/><br/><code>&lt;img /&gt;<br/>&lt;input /&gt;<br/>&lt;select&gt;<br/>&lt;option&gt;<br/>&lt;br /&gt;<br/></code><br/><br/>Generally, tags that don't have content don't support pseudo-elements.<br/><br/>This list may be incomplete, I will add tags as I learn about them.`,
    },
    filters: ['html', 'css'],
  },
  {
    title: 'Custom console.log',
    content: {
      __html: `In order to customize your console.log, you need to use '%c' before the text you want to style. After that, as a second argument, you will pass css code as string.<br/><br/><code>console.log('%cRed text', 'color: red')</code><br/>You can even style each word differently by adding '%c' in front of them, and then add a new css string one by one.<br/><br/>Sadly there is only a couple of css properties that are supported.<br/><br/><code>background (all), font (all), padding, color, ..?</code>`,
    },
    filters: ['angular', 'react'],
  },
  {
    title: 'FLEX:  Same height of elements',
    content: {
      __html: `For items in flex container to have the same height (in one row) parent must have<br/><br/><code>display: flex;<br/>align-items: normal;</code><br/><br/>And children must have<br/><br/><code>display: flex;</code><br/><br/>If you want all items to have the same heigh, either use 'height' or 'grid'.`,
    },
    filters: ['important', 'css'],
  },
  {
    title: 'Custom scrollbar',
    content: {
      __html: `Webkit browsers:<br/><br/><code>::-webkit-scrollbar {<br/>&nbsp;&nbsp;width: 0.3rem;<br/>&nbsp;&nbsp;height: 0.3rem;<br/>&nbsp;&nbsp;background: transparent;<br/><br/>&nbsp;&nbsp;&-thumb {<br/>&nbsp;&nbsp;&nbsp;&nbsp;background: yellow;<br/>&nbsp;&nbsp;}<br/>}</code><br/><br/>Firefox:<br/><br/><code>* {<br/>&nbsp;&nbsp;scrollbar-width: thin;<br/>&nbsp;&nbsp;scrollbar-color: yellow transparent;<br/>}</code>`,
    },
    filters: ['css'],
  },
  {
    title: 'Custom selection',
    content: {
      __html: `<code>::selection {<br/>&nbsp;&nbsp;background-color: yellow;<br/>&nbsp;&nbsp;color: black;<br/>}</code>`,
    },
    filters: ['css'],
  },
  {
    title: 'Restart CSS animation',
    content: {
      __html: `When you want to make animation like 'Ripple' on click ( or the one I have on my cursor on click ), you need to make 2 identical animations with different names and switch between them.<br/><br/>You can't just remove and apply class for the same animation. Because it will only continue where it left of.<br/><br/><br/><a class='link' href='https://css-tricks.com/restart-css-animation/'>Read more on this topic here</a>`,
    },
    filters: ['css'],
  },
  {
    title: 'Screen Resolutions',
    content: {
      __html: `8K : 7680x4320<br/><br/>4K : 3840x2160<br/><br/>2K (QHD) : 2560x1440<br/><br/>FHD : 1920x1080<br/><br/>1600x900<br/><br/>1366x768<br/><br/>360x780<br/><br/>360x640<br/><br/><a href='https://gs.statcounter.com/screen-resolution-stats' class='link'>See stats here</a>`,
    },
    filters: ['important', 'mq'],
  },
  {
    title: 'Clip-path with box-shadow',
    content: {
      __html: `If you want to have shadow on your 'clip-path' element you can't use 'box-shadow'.<br/><br/>What you want to do is wrap clipped element in a div (for example) and then apply filter to that div:<br/><br/><code>filter: drop-shadow(...);</code>`,
    },
    filters: ['css'],
  },
  {
    title: 'Clip-path animation',
    content: {
      __html: `When animating 'clip-path' elements you must make sure that both clipping states have the same number of points. Otherwise the transition will not work and switch will be instant.<br/><br/><a class='link' href='https://bennettfeely.com/clippy/'>Tool for clip-path</a>`,
    },
    filters: ['css'],
  },
  {
    title: 'GRID: Template',
    content: {
      __html: `Making layout with grid in 3 lines (apply to wrapper):<br/><br/><code>display: grid;<br/>grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);<br/>grid-gap: 5px 5px;</code><br/><br/>Number of 'minmax(0, 1fr)' indicates how many columns you will have in your layout.<br/><br/>In 'grid-gap' first value is for 'y-axis' and second value is for 'x-axis'.`,
    },
    filters: ['important', 'css'],
  },
  {
    title: 'Text-align-last',
    content: {
      __html: `Aligning text to '<i>center</i>' is not as nice looking as having it '<i>justified</i>'. But since justified is aligned to left it doesn't have quite the same effect.<br><br>To get around this there is this css property: <br><br> <code>text-align-last: center</code> <br><br> As you can see from the property name, it aligns only the last row of text. So we can have justified text with centered last row for cool UI result.`,
    },
    filters: ['css'],
  },
  {
    title: 'New Absolute Centering',
    content: {
      __html: `OLD way:<br/><br/><code>position: absolute;<br/>top: 0;<br/>left: 0;<br/>transform: translate(-50%, -50%);</code><br/><br/>NEW way:<br/><br/><code>position: absolute;<br/>inset: 0;<br/>margin: auto;</code>`,
    },
    filters: ['important', 'css'],
  },
];
