import React from 'react';

export const GitIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.83'
      height='20.83'
      viewBox='0 0 20.83 20.83'
    >
      <path
        id='git'
        d='M10.415,0A10.456,10.456,0,0,0,0,10.415,10.81,10.81,0,0,0,7.974,20.83V17.384a2.508,2.508,0,0,1-2.811-1.251c-.237-.4-.656-.832-1.094-.8l-.107-1.216a2.51,2.51,0,0,1,2.25,1.393A1.35,1.35,0,0,0,7,16.18a1.64,1.64,0,0,0,1.025-.089,2.861,2.861,0,0,1,.718-1.468h0c-2.713-.4-3.794-1.844-4.223-2.98A4.779,4.779,0,0,1,5.256,7.06.164.164,0,0,0,5.3,6.933a4,4,0,0,1,.121-2.667,4.217,4.217,0,0,1,2.311.87l.293.176c.122.073.084.031.206.022a8.839,8.839,0,0,1,2.186-.308,8.877,8.877,0,0,1,2.216.315l.095.01c-.008,0,.026-.006.083-.04,2.114-1.281,2.038-.862,2.606-1.046a4.063,4.063,0,0,1,.119,2.668,5,5,0,0,1,.783,4.709c-.429,1.136-1.51,2.576-4.223,2.98h0a2.678,2.678,0,0,1,.763,1.905v4.3A10.809,10.809,0,0,0,20.83,10.415,10.455,10.455,0,0,0,10.415,0Zm0,0'
        fill='currentColor'
      />
    </svg>
  );
};
