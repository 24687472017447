import React from 'react';

import { CSSForm, CSSFormProps } from '../CSSForm';
import { cssLinks } from './data/links.data';

export const LinksPage = () => {
  const props: CSSFormProps = {
    title: 'Links',
    links: [
      {
        text: 'Notes',
        linkTo: '/css/notes',
      },
      {
        text: 'Blog',
        linkTo: '/css/blog',
      },
    ],
    data: cssLinks,
  };

  return <CSSForm title={props.title} links={props.links} data={props.data} />;
};
