import React from 'react';

export const MoonIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.83'
      height='20.83'
      viewBox='0 0 20.83 20.83'
    >
      <path
        id='moon'
        d='M10.617,20.83a10.494,10.494,0,0,1-4.04-.8A10.705,10.705,0,0,1,.8,14.254,10.61,10.61,0,0,1,7.5.069,1.533,1.533,0,0,1,9.261.716a1.535,1.535,0,0,1-.152,1.869,8.688,8.688,0,0,0-2.4,5.433,5.224,5.224,0,0,0,.113,1.109A6.351,6.351,0,0,0,11.71,14a5.3,5.3,0,0,0,1.142.114,9.04,9.04,0,0,0,5.428-2.344,1.53,1.53,0,0,1,2.481,1.592,10.557,10.557,0,0,1-1.505,3.026.814.814,0,0,1-1.323-.948A8.936,8.936,0,0,0,19.1,13.216a12,12,0,0,1-2.587,1.647,8.787,8.787,0,0,1-3.645.88,6.937,6.937,0,0,1-1.492-.15A8.01,8.01,0,0,1,5.235,9.465a6.854,6.854,0,0,1-.149-1.455,10.315,10.315,0,0,1,2.622-6.3A8.989,8.989,0,0,0,10.785,19.2a8.851,8.851,0,0,0,4.236-1.155.814.814,0,1,1,.8,1.417,10.46,10.46,0,0,1-5.006,1.365Z'
        transform='translate(-0.001 0)'
        fill='currentColor'
      />
    </svg>
  );
};
