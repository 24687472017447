/*************************************************  Imports  *************************************************/
import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import rehypeHighlight from 'rehype-highlight';

import { useLocation, NavLink, useHistory } from 'react-router-dom';

import { BlogPosts } from '../data/blog-posts.data';
import { getColor } from '../../../../../assets/style/colors/colors';
import { RouterPage } from '../../../RouterPage';
import { getVar } from '../../../../../assets/style/variables/variables';
import { getFont } from '../../../../../assets/style/fonts/fonts';

/*************************************************  Component  *************************************************/
export const BlogPost = () => {
  const [mdValue, setMdValue] = useState('');
  const location = useLocation();
  const history = useHistory();

  const post = BlogPosts.find(
    (element) => element.linkTo === location.pathname.split('/')[3]
  );

  useEffect(() => {
    if (!post) {
      history.replace('/404');
    }
  }, [post, history, location]);

  useEffect(() => {
    fetch(post?.markdown)
      .then((res) => res.text())
      .then((post) => setMdValue(post))
      .catch((err) => console.error(err));
  }, [post]);

  /*************************************************  Template  *************************************************/
  return (
    <BlogPostPage>
      <BlogPostContainer>
        <BackLink to='/css/blog'>Back to all posts</BackLink>
        <ReactMarkdown rehypePlugins={[rehypeHighlight]}>
          {mdValue}
        </ReactMarkdown>
      </BlogPostContainer>
    </BlogPostPage>
  );
};

const BlogPostPage = styled(RouterPage)`
  background-color: ${getColor('section')};

  @media (max-width: 855px), (max-height: 701px) {
    padding-top: 8rem;
  }
`;

const BlogPostContainer = styled.div`
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  word-break: break-word;
  font-size: 1.6rem;

  h1 {
    font-size: 3rem;
    color: ${getColor('heading')};
    margin-bottom: 4.5rem;

    @media (max-width: 500px) {
      font-size: 2.5rem;
    }
    @media (max-width: 319px) {
      font-size: 2rem;
    }
  }

  h2 {
    color: ${getColor('heading')};
    margin: 6rem 0 3rem 0;

    @media (max-width: 500px) {
      font-size: 2rem;
    }
  }

  h4 {
    margin-bottom: -1.5rem;
  }

  p {
    line-height: 1.8;
    margin: 2.5rem 0;
  }

  ul {
    list-style-type: disc;

    li {
      margin-left: 5rem;
      line-height: 1.8;
    }
  }

  hr {
    margin: 6rem 0 3rem 0;
  }

  a {
    color: ${getColor('text')};
    text-decoration: underline;
  }

  code:not([class]) {
    color: ${getColor('text')};
    background-color: ${getColor('nav')};
    padding: 0 0.5rem;
    font-family: ${getFont('code')};
    transition: 0.5s;
    font-size: 1.5rem;
  }

  hr {
    border-color: ${getColor('text', 0.1)};
  }

  pre {
    /* common */
    --code-bg: #201a33;
    --comment: rgba(255, 255, 255, 0.3);
    --keyword: #e475ff;
    --type-string: #7effa4;
    --type-number: #ffc107;

    /* css */
    --css-id: #ffff00;
    --css-prop: #f8f8ff;
    --css-variable: #ff1493;
    --css-selector-pseudo: #56c2b6;
    --css-selector-tag: #deb887;
    --css-selector-class: #87ceeb;

    /* ts */
    --ts-operator: #56c2b6;
    --ts-attr: #f08080;
    --ts-title: #ffff00;
    --ts-function: #00eeff;

    background: var(--code-bg);
    border-radius: 0.5rem;
    padding: 3rem 1.5rem;
    margin: 3rem 0;
    overflow-x: auto;
    line-height: 1.6;
    tab-size: 4;
    -moz-tab-size: 4;
    hyphens: none;

    code {
      font-family: ${getFont('code')};

      &.language-scss {
        span {
          &.hljs-attribute {
            color: var(--css-prop);
          }

          &.hljs-selector-attr {
            color: var(--ts-attr);
          }

          &.hljs-selector-tag {
            color: var(--css-selector-tag);
          }

          &.hljs-selector-class {
            color: var(--css-selector-class);
          }

          &.hljs-selector-pseudo {
            color: var(--css-selector-pseudo);
          }

          &.hljs-selector-id {
            color: var(--css-id);
          }

          &.hljs-variable {
            color: var(--css-variable);
          }

          &.hljs-meta {
            color: crimson;
          }
        }
      }

      &.language-ts {
        color: #ccc;

        span {
          &.hljs-attr {
            color: var(--ts-attr);
          }

          &.hljs-meta {
            color: var(--ts-function);
          }

          &.hljs-title {
            color: var(--ts-title);
          }

          &.hljs-function {
            color: var(--ts-function);
          }

          &.hljs-built_in {
            color: var(--ts-operator);
          }

          &.hljs-regexp {
            color: var(--type-string);
          }

          &.hljs-subst {
            color: var(--css-variable);
          }
        }
      }
    }

    span {
      &.hljs-comment {
        color: var(--comment);
      }

      &.hljs-keyword {
        color: var(--keyword);
      }

      &.hljs-string {
        color: var(--type-string);
      }

      &.hljs-number {
        color: var(--type-number);
      }
    }
  }
`;

const BackLink = styled(NavLink)`
  display: block;
  color: ${getColor('text', 0.5)};
  font-size: 1.4rem;
  transition: 0.5s;
  margin-bottom: ${getVar('appSpacing')};
  text-decoration: none !important;

  &::before {
    content: '➟';
    color: ${getColor('accent', 0.5)};
    padding-left: 1rem;
    transform: scaleX(-1);
    transition: 0.5s;
    display: inline-block;
  }

  @media (hover: hover) {
    &:hover {
      text-decoration: underline !important;
      color: ${getColor('text', 1)};

      &::before {
        color: ${getColor('accent', 1)};
      }
    }
  }
`;
