import React from 'react';

export const MQIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='33.75'
      height='30'
      viewBox='0 0 33.75 30'
      className={props.className}
    >
      <path
        id='mq'
        d='M36.75,7.75v22.5A3.75,3.75,0,0,1,33,34H6.75A3.75,3.75,0,0,1,3,30.25V7.75A3.75,3.75,0,0,1,6.75,4H33a3.75,3.75,0,0,1,3.75,3.75m-16.875,7.5A2.016,2.016,0,0,0,18,13.375H9.563A2.016,2.016,0,0,0,7.688,15.25v9.375H10.5V16.188h1.875V22.75h2.813V16.188h1.875v8.438h2.813V15.25m4.688-1.875a1.875,1.875,0,0,0-1.875,1.875v7.5a1.875,1.875,0,0,0,1.875,1.875h1.875v2.813h2.344V24.625h1.406a1.875,1.875,0,0,0,1.875-1.875v-7.5a1.875,1.875,0,0,0-1.875-1.875H24.563m.938,2.813h3.75v5.625H25.5Z'
        transform='translate(-3 -4)'
        fill='currentColor'
      />
    </svg>
  );
};
