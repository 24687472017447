import React from 'react';

export const HeartIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='23'
      height='19.436'
      viewBox='0 0 23 19.436'
    >
      <path
        id='heart'
        d='M11.5,59.108a.9.9,0,0,1-.7-.335,22.842,22.842,0,0,0-4.02-3.466C3.6,52.936,0,50.25,0,46.2a6.587,6.587,0,0,1,1.812-4.751A6.1,6.1,0,0,1,6.2,39.672a5.759,5.759,0,0,1,5.273,2.811l.028.048.028-.048A5.759,5.759,0,0,1,16.8,39.672a6.1,6.1,0,0,1,4.388,1.775A6.587,6.587,0,0,1,23,46.2a7.3,7.3,0,0,1-1.614,4.475,19.047,19.047,0,0,1-3.491,3.365.9.9,0,0,1-1.108-1.415c2.294-1.8,4.416-3.817,4.416-6.425,0-2.784-1.81-4.729-4.4-4.729a4.034,4.034,0,0,0-3.7,1.885A5.223,5.223,0,0,0,12.4,45.39a.9.9,0,0,1-1.791,0A5.226,5.226,0,0,0,9.9,43.353a4.034,4.034,0,0,0-3.7-1.885c-2.592,0-4.4,1.945-4.4,4.729,0,3.15,3.079,5.447,6.057,7.668a31.733,31.733,0,0,1,3.646,3,19.422,19.422,0,0,1,1.692-1.505.9.9,0,0,1,1.125,1.4A13.722,13.722,0,0,0,12.2,58.773.9.9,0,0,1,11.5,59.108Z'
        transform='translate(0 -39.672)'
        fill='currentColor'
      />
    </svg>
  );
};
