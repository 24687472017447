/*************************************************  Imports  *************************************************/
import React from 'react';
import styled from '@emotion/styled';
import { CSSCardModel } from './css-card.model';
import { getColor } from '../../../../assets/style/colors/colors';
import { getVar } from '../../../../assets/style/variables/variables';
import { CSSIcon } from '../../../../assets/icon-components/filter-icons/CSSIcon';
import { HTMLIcon } from '../../../../assets/icon-components/filter-icons/HTMLIcon';
import { SVGIcon } from '../../../../assets/icon-components/filter-icons/SVGIcon';
import { MQIcon } from '../../../../assets/icon-components/filter-icons/MQIcon';
import { AngularIcon } from '../../../../assets/icon-components/filter-icons/AngularIcon';
import { ReactIcon } from '../../../../assets/icon-components/filter-icons/ReactIcon';
import { getFont } from '../../../../assets/style/fonts/fonts';

/*************************************************  Component  *************************************************/
export const CSSCard = (props: CSSCardModel) => {
  const iconSet = {
    important: null,
    css: <CSSIcon />,
    html: <HTMLIcon />,
    svg: <SVGIcon />,
    mq: <MQIcon />,
    angular: <AngularIcon />,
    react: <ReactIcon />,
  };

  /*************************************************  Methods  *************************************************/
  function renderImportant() {
    return props.filters.indexOf('important') !== -1 ? '!important;' : '';
  }

  /*************************************************  Template  *************************************************/
  return (
    <StyledCard className='cssard'>
      <CardTitleLink href={props.linkTo}>{props.title}</CardTitleLink>
      <CardContent dangerouslySetInnerHTML={props.content}></CardContent>
      <CardFilters>
        <CardImportant>{renderImportant()}</CardImportant>
        <CardCategories>
          {props.filters.map((element) => {
            return element !== 'important' ? (
              <span key={element}>{iconSet[element]}</span>
            ) : (
              ''
            );
          })}
        </CardCategories>
      </CardFilters>
    </StyledCard>
  );
};

/*************************************************  Styles  *************************************************/
const StyledCard = styled.div`
  width: 45rem;
  min-height: 50rem;
  max-height: 60rem;
  height: 100%;
  background: ${getColor('gradientDark')};
  box-shadow: 0 0 1rem ${getColor('shadow', 0.2)};
  transition: 0.2s;
  padding: ${getVar('appSpacing')};
  flex-shrink: 0;
  position: relative;

  @media (max-width: 499px) {
    min-height: 47rem;
  }

  &:not(:first-of-type) {
    margin-left: -15rem;

    @media (max-width: 500px) {
      margin-left: -5rem;
    }
  }

  &:not(:last-of-type):hover {
    transform: translateY(-2rem);
    z-index: 2;

    & ~ .cssard {
      transform: translateX(14rem);

      @media (max-width: 500px) {
        transform: translateX(4rem);
      }
    }
  }

  @media (max-width: 555px) {
    width: 100%;
  }

  /* Allowed inline tags */
  code {
    display: block;
    font-family: ${getFont('code')};
    font-weight: 200;
    margin-left: 2rem;
    font-size: 1.3rem;

    @media (max-width: 500px) {
      font-size: 1rem;
    }
  }

  a.link {
    display: block;
    color: ${getColor('text', 0.5)};
    position: relative;

    &::after {
      position: absolute;
      content: '➟';
      color: ${getColor('accent')};
      margin-left: 1rem;
    }

    @media (hover: hover) {
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;

const CardTitleLink = styled.a`
  display: block;
  margin-bottom: 3rem;
  font-size: 3rem;
  color: ${getColor('heading')};
  transition: 0.5s;

  &[href] {
    position: relative;

    &::after {
      content: '➟';
      color: ${getColor('accent')};
      margin-left: 1rem;
    }
  }

  @media (hover: hover) {
    &[href]:hover {
      color: ${getColor('accent')};
    }
  }

  @media (max-width: 500px) {
    font-size: 2rem;
  }

  @media (max-width: 350px) {
    font-size: 1.5rem;
  }
`;

const CardContent = styled.div`
  font-size: 1.6rem;

  @media (max-width: 500px) {
    font-size: 1.3rem;
  }
`;

const CardFilters = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem;
`;

const CardImportant = styled.div`
  font-family: ${getFont('heading')};
  font-size: 2.4rem;
  color: ${getColor('accent')};

  @media (max-width: 350px) {
    font-size: 2rem;
  }
`;

const CardCategories = styled.div`
  color: ${getColor('text')};

  & > span > svg {
    width: 2rem;
    height: 2rem;
    margin-left: 1.5rem;
  }

  @media (max-width: 350px) {
    & > span > svg {
      width: 1.5rem;
      height: 1.5rem;
    }
  }
`;
