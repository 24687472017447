/*************************************************  Imports  *************************************************/
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { useLocation, NavLink } from 'react-router-dom';

import { getColor } from '../../../assets/style/colors/colors';
import { getVar } from '../../../assets/style/variables/variables';

import { LogoIcon } from '../../../assets/icon-components/LogoIcon';
import { SkillsIcon } from '../../../assets/icon-components/SkillsIcon';
import { HeartIcon } from '../../../assets/icon-components/HeartIcon';
import { getFont } from '../../../assets/style/fonts/fonts';

/*************************************************  Component  *************************************************/
export const SidebarRouterLinks = () => {
  /*************************************************  Hooks  *************************************************/
  const location = useLocation();

  useEffect(() => {}, [location]);

  /*************************************************  Properties  *************************************************/
  const routes = [
    {
      route: '/',
      icon: () => (
        <LogoIcon
          style={{
            width: '4rem',
            height: '4rem',
            maxWidth: '4rem',
            maxHeight: '4rem',
          }}
        />
      ),
      text: 'Home',
    },
    {
      route: '/skills',
      icon: () => <SkillsIcon />,
      text: 'Skills',
    },
    {
      route: '/css',
      icon: () => <HeartIcon />,
      text: 'CSS',
    },
  ];

  const activeLinkStyle = {
    color: getColor('accent'),
  };

  return (
    <StyledUl>
      {routes.map((route) => {
        return (
          <StyledLi
            key={route.text}
            className={location.pathname === route.route ? 'pe-none' : ''}
          >
            <StyledNavLink
              to={route.route}
              activeStyle={activeLinkStyle}
              exact={route.route === '/'}
              className={
                location.pathname.includes(route.route) && route.route !== '/'
                  ? 'subroute'
                  : ''
              }
            >
              {route.icon()}
              <span>{route.text}</span>
            </StyledNavLink>
          </StyledLi>
        );
      })}
    </StyledUl>
  );
};

/*************************************************  Styles  *************************************************/
const StyledUl = styled.ul`
  width: 100%;
  flex: 1;
`;

const StyledLi = styled.li`
  position: relative;
  height: calc(100% / 3);
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 0.1rem solid ${getColor('text', 0.2)};

  @media (max-width: 855px), (max-height: 701px) {
    width: 100%;
    height: 15rem;
    border: none;
  }

  @media (max-height: 799px) {
    height: 12rem;
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${getColor('inherit')};

  & > span {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 1.2rem;
    text-transform: uppercase;
    color: ${getColor('text')};
    background-color: ${getColor('nav')};
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: 0.5s;
  }

  & > svg {
    min-width: 2rem;
    max-width: 2.4rem;
    min-height: 2rem;
    max-height: 2.4rem;
  }

  @media (hover: hover) {
    &:hover {
      color: ${getColor('transparent')};

      & > span {
        opacity: 1;
      }
    }

    &.subroute:hover {
      color: ${getColor('accent')};

      & > span {
        opacity: 0;
      }
    }
  }

  &.active::before {
    position: absolute;
    content: '';
    width: ${getVar('sidebarWidth')};
    height: 100%;
    top: 0;
    left: 0;
    background: var(--nav-active);
    z-index: -1;
  }

  @media (max-width: 855px), (max-height: 701px) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > span {
      position: static;
      top: unset;
      left: unset;
      text-transform: lowercase;
      height: unset;
      opacity: 1;
      font-size: 2.4rem;
      font-family: ${getFont('heading')};
      background-color: transparent;
    }

    & > svg {
      width: 2.4rem;
      height: 2.4rem;
      margin: 0 auto;
      display: block;
      margin-bottom: 1rem;
    }

    &.active {
      &::before {
        display: none;
      }

      & > span {
        color: ${getColor('accent')};
      }
    }

    &.subroute:hover {
      & > span {
        opacity: 1;
      }
    }

    &:hover {
      color: ${getColor('text')};
    }
  }
`;
