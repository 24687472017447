/*************************************************  Imports  *************************************************/
import React from 'react';
import styled from '@emotion/styled';

import { NavLink } from 'react-router-dom';
import { RouterPage } from '../../RouterPage';

import { getColor } from '../../../../assets/style/colors/colors';
import { getVar } from '../../../../assets/style/variables/variables';
import { getFont } from '../../../../assets/style/fonts/fonts';
import { BlogPosts } from './data/blog-posts.data';

/*************************************************  Component  *************************************************/
export const BlogPage = () => {
  /*************************************************  Properties  *************************************************/
  const headerProps = {
    title: 'Blog',
    links: [
      {
        text: 'Notes',
        linkTo: '/css/notes',
      },
      {
        text: 'Links',
        linkTo: '/css/links',
      },
    ],
  };

  /*************************************************  Template  *************************************************/
  return (
    <StyledRouterPage>
      <StyledHeader>
        <TitleContainer>
          <StyledTitle>{headerProps.title}</StyledTitle>
          {headerProps.links?.map((element) => {
            return (
              <StyledTitleLink to={element.linkTo} key={element.text}>
                {element.text}
              </StyledTitleLink>
            );
          })}
        </TitleContainer>
      </StyledHeader>

      <MainContainer>
        {BlogPosts.map((element) => {
          return (
            <StyledBlogLink to={`blog/${element.linkTo}`} key={element.title}>
              <LinkTitle>{element.title}</LinkTitle>
              <LinkDate>{element.date}</LinkDate>
            </StyledBlogLink>
          );
        })}
      </MainContainer>
    </StyledRouterPage>
  );
};

/*************************************************  Styles  *************************************************/
const StyledRouterPage = styled(RouterPage)`
  background: ${getColor('section')};
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${getVar('appSpacing')};
  padding-top: 6rem;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: ${getVar('appSpacing')} 4.5rem;
    align-items: center;
  }

  @media (max-width: 450px) {
    padding: ${getVar('appSpacing')} 2rem;
  }

  @media (max-width: 319px) {
    padding-top: 6rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    bottom: -1.5rem;
    left: calc(${getVar('appSpacing')} * (-1));
    width: 12rem;
    height: 0.1rem;
    background: ${getColor('text')};
  }

  @media (max-width: 800px) {
    &::before {
      display: none;
    }
  }
`;

const StyledTitle = styled.h1`
  font-family: ${getFont('heading')};
  font-size: 5rem;
  font-weight: normal;
  line-height: 1;
  margin-left: 2rem;
  margin-right: 5rem;
  color: ${getColor('heading')};

  @media (max-width: 700px) {
    font-size: 4rem;
  }

  @media (max-width: 450px) {
    font-size: 3rem;
    margin-right: 3rem;
  }

  @media (max-width: 350px) {
    margin-right: 2rem;
  }

  @media (max-width: 319px) {
    margin-left: 0;
  }
`;

const StyledTitleLink = styled(NavLink)`
  font-family: ${getFont('heading')};
  font-size: 2.4rem;
  line-height: 1;
  margin-right: 3rem;
  transition: 0.5s;
  color: ${getColor('text', 0.5)};

  &:hover {
    color: ${getColor('text', 1)};
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: 350px) {
    margin-right: 2rem;
  }

  @media (max-width: 319px), (max-height: 567px) {
    display: none;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  flex: auto;
  display: flex;
  flex-direction: column;
  padding: ${getVar('appSpacing')};
`;

const StyledBlogLink = styled(NavLink)`
  margin-left: 2rem;
  padding: 3rem 2rem;
  margin-bottom: 4rem;
  position: relative;

  &:last-of-type {
    margin-bottom: 0;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 0.2rem;
    height: 35%;
    background: ${getColor('accent')};
    z-index: 1;
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: calc((${getVar('appSpacing')} + 2rem) * -1);
    width: 0;
    height: 100%;
    transition: 0.5s;
    background: ${getColor('shadow', 0.1)};
  }

  &:hover {
    &::after {
      width: calc(100% + 2rem + (${getVar('appSpacing')} * 2));
    }
  }

  @media (max-width: 350px) {
    margin-left: 0;
  }
`;

const LinkTitle = styled.p`
  color: ${getColor('heading')};
  font-size: 2rem;
  margin-bottom: 1.5rem;
  line-height: 3rem;
  position: relative;
  z-index: 1;

  @media (max-width: 450px) {
    font-size: 1.8rem;
  }

  @media (max-width: 350px) {
    font-size: 1.6rem;
  }
`;

const LinkDate = styled.p`
  color: ${getColor('text')};
  font-size: 1.7rem;
  position: relative;
  z-index: 1;

  @media (max-width: 450px) {
    font-size: 1.5rem;
  }

  @media (max-width: 350px) {
    font-size: 1.3rem;
  }
`;
