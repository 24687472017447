/*************************************************  Importas  *************************************************/
import React from 'react';
import styled from '@emotion/styled';

import { getColor } from '../../../assets/style/colors/colors';
import { getVar } from '../../../assets/style/variables/variables';

import { MailIcon } from '../../../assets/icon-components/MailIcon';
import { LinkedIcon } from '../../../assets/icon-components/LinkedIcon';
import { GitIcon } from '../../../assets/icon-components/GitIcon';
// import { CodeIcon } from '../../../assets/icon-components/CodeIcon';
import { CVIcon } from '../../../assets/icon-components/CVIcon';

/*************************************************  Component  *************************************************/
export const SocialLinks = () => {
  /*************************************************  Properties  *************************************************/
  const links = [
    {
      title: 'E-mail',
      href: 'mailto: joakimz.web@gmail.com',
      icon: () => <MailIcon />,
      download: false,
    },
    {
      title: 'LinkedIn',
      href: 'https://www.linkedin.com/in/joakimzebic',
      icon: () => <LinkedIcon />,
      download: false,
    },
    {
      title: 'GitHub',
      href: 'https://github.com/JoakimZebic',
      icon: () => <GitIcon />,
      download: false,
    },
    // {
    //   title: 'Codepen',
    //   href: 'https://codepen.io/CssWizard',
    //   icon: () => <CodeIcon />,
    //   download: false,
    // },
    {
      title: 'Download my CV',
      href: '/Joakim_Zebić.pdf',
      icon: () => <CVIcon />,
      download: false,
    },
  ];

  /*************************************************  Template  *************************************************/
  return (
    <SocialContainer>
      {links.map((link) => {
        return (
          <SocialLink
            key={link.title}
            href={link.href}
            title={link.title}
            download={link.download}
          >
            {link.icon()}
          </SocialLink>
        );
      })}
    </SocialContainer>
  );
};

/*************************************************  Styles  *************************************************/
const SocialContainer = styled.div`
  padding: ${getVar('appSpacing')} 0 ${getVar('itemSpacing')} 0;

  @media (min-width: 602px) and (max-height: 799px) {
    padding: ${getVar('itemSpacing')} 0 ${getVar('itemSpacing')} 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 100%;
  }

  @media (max-height: 799px) and (max-width: 855px) {
    margin: ${getVar('itemSpacing')} 0 0 0;
  }

  @media (max-width: 855px), (max-height: 701px) {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-top: 0.1rem solid;
    margin: calc(${getVar('itemSpacing')} * 2) 0 0 0;
    flex-direction: row;
  }

  @media (max-width: 499px) {
    justify-content: space-around;
  }
`;

const SocialLink = styled.a`
  margin-bottom: ${getVar('itemSpacing')};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${getColor('text')};
  transition: 0.5s;

  & > svg {
    min-width: 2rem;
    max-width: 2.4rem;
    min-height: 2rem;
    max-height: 2.4rem;
  }

  &:hover {
    color: ${getColor('heading')};
  }

  @media (min-width: 602px) and (max-height: 799px) {
    margin: 0;
  }

  @media (max-width: 855px), (max-height: 701px) {
    margin-bottom: 0;
    margin-right: calc(${getVar('itemSpacing')} * 2);

    & > svg {
      width: 2.4rem;
      height: 2.4rem;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  @media (max-width: 499px) {
    margin: 0;
  }
`;
