/*************************************************  Imports  *************************************************/
import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { NavLink } from 'react-router-dom';

import { getVar } from '../../../assets/style/variables/variables';
import { getColor } from '../../../assets/style/colors/colors';

import { RouterPage } from '../RouterPage';
import { CSSCard } from './CSSCard/CSSCard';
import { CSSCardModel, CSSFiltersModel } from './CSSCard/css-card.model';

import { SearchIcon } from '../../../assets/icon-components/SearchIcon';
import { ImportantIcon } from '../../../assets/icon-components/filter-icons/ImportantIcon';
import { CSSIcon } from '../../../assets/icon-components/filter-icons/CSSIcon';
import { HTMLIcon } from '../../../assets/icon-components/filter-icons/HTMLIcon';
import { SVGIcon } from '../../../assets/icon-components/filter-icons/SVGIcon';
import { MQIcon } from '../../../assets/icon-components/filter-icons/MQIcon';
import { ReactIcon } from '../../../assets/icon-components/filter-icons/ReactIcon';
import { AngularIcon } from '../../../assets/icon-components/filter-icons/AngularIcon';
import { XIcon } from '../../../assets/icon-components/XIcon';
import { NopeIcon } from '../../../assets/icon-components/filter-icons/NopeIcon';
import { getFont } from '../../../assets/style/fonts/fonts';

/*************************************************  Props Model  *************************************************/
export interface CSSFormProps {
  title: string;
  links?: {
    text: string;
    linkTo: string;
  }[];
  data: CSSCardModel[];
}

/*************************************************  Component  *************************************************/
export const CSSForm = (props: CSSFormProps) => {
  /*************************************************  State  *************************************************/
  const [filterState, setFilterState] = useState({
    searchTerm: '',
    categories: [] as CSSFiltersModel[],
  });

  const [filteredData, setFilteredData] = useState(props.data);

  const [searchValue, setSearchValue] = useState('');

  const [checkedFilters, setCheckedFilters] = useState({
    important: false,
    css: false,
    html: false,
    svg: false,
    mq: false,
    angular: false,
    react: false,
  });

  /*************************************************  Methods  *************************************************/
  // ------------------- SEARCH -------------------//
  function onSearchInput(event: any) {
    setSearchValue(event.target.value);

    setFilterState({
      searchTerm: event.target.value,
      categories: filterState.categories,
    });
  }

  function renderSearchIcon() {
    if (!filterState.searchTerm) {
      return <StyledSearchIcon />;
    } else {
      return (
        <StyledXButton onClick={() => clearSearch()}>
          <XIcon />
        </StyledXButton>
      );
    }
  }

  function clearSearch() {
    setSearchValue('');
    setFilterState({ searchTerm: '', categories: filterState.categories });
  }

  // ------------------- FILTERS -------------------//
  function removeFilter(value: CSSFiltersModel) {
    const index = filterState.categories.indexOf(value);
    filterState.categories.splice(index, 1);
  }

  function onFilterChange(event: any, value: CSSFiltersModel) {
    checkedFilters[value] = event.target.checked;

    event.target.checked
      ? filterState.categories.push(value)
      : removeFilter(value);

    setFilterState({
      searchTerm: filterState.searchTerm,
      categories: filterState.categories,
    });
  }

  useEffect(() => {
    const filterBySearchTerm = (element: CSSCardModel) => {
      return (
        element.title
          .toLowerCase()
          .includes(filterState.searchTerm.toLowerCase()) ||
        element.content?.__html
          .toLowerCase()
          .includes(filterState.searchTerm.toLowerCase())
      );
    };

    const filterByFilters = (element: CSSCardModel) => {
      if (filterState.categories.length) {
        let filterExist = false;

        for (const e of element.filters) {
          if (filterState.categories.indexOf(e) !== -1) {
            filterExist = true;
          }
        }

        return filterExist;
      } else return true;
    };

    const results = props?.data.filter((e) => {
      // console.log(filterByFilters(e));
      return filterBySearchTerm(e) && filterByFilters(e);
    });

    setFilteredData(results);
  }, [filterState, props]);

  function renderClearButton() {
    if (filterState.categories.length) {
      return (
        <ClearButton onClick={() => clearFilters()}>
          <XIcon />
        </ClearButton>
      );
    }
  }

  function clearFilters() {
    setCheckedFilters({
      important: false,
      css: false,
      html: false,
      svg: false,
      mq: false,
      angular: false,
      react: false,
    });

    setFilterState({
      searchTerm: filterState.searchTerm,
      categories: [],
    });
  }

  function renderCards() {
    if (filteredData.length) {
      return filteredData.map((element) => {
        return (
          <CSSCard
            title={element.title}
            filters={element.filters}
            content={element.content}
            key={element.title}
            linkTo={element.linkTo}
          />
        );
      });
    } else return <StyledNopeIcon />;
  }

  /*************************************************  Template  *************************************************/
  return (
    <StyledRouterPage>
      <StyledHeader>
        <TitleContainer>
          <StyledTitle>{props.title}</StyledTitle>
          {props.links?.map((element) => {
            return (
              <StyledTitleLink to={element.linkTo} key={element.text}>
                {element.text}
              </StyledTitleLink>
            );
          })}
        </TitleContainer>

        <SearchContainer>
          <SearchInput
            id='search'
            type='text'
            placeholder='Search...'
            value={searchValue}
            onChange={(event) => onSearchInput(event)}
          />
          {renderSearchIcon()}
        </SearchContainer>
      </StyledHeader>

      <MainContainer>
        <FiltersContainer>
          <FiltersHolder>
            <FilterWrap>
              <HiddenCheckbox
                type='checkbox'
                name='filters'
                id='important'
                value='important'
                onChange={(event) => onFilterChange(event, 'important')}
                checked={checkedFilters.important}
              />
              <StyledLabel htmlFor='important'>
                <ImportantIcon />
              </StyledLabel>
            </FilterWrap>

            <FilterWrap>
              <HiddenCheckbox
                type='checkbox'
                name='filters'
                id='css'
                value='css'
                onChange={(event) => onFilterChange(event, 'css')}
                checked={checkedFilters.css}
              />
              <StyledLabel htmlFor='css'>
                <CSSIcon />
              </StyledLabel>
            </FilterWrap>

            <FilterWrap>
              <HiddenCheckbox
                type='checkbox'
                name='filters'
                id='html'
                value='html'
                onChange={(event) => onFilterChange(event, 'html')}
                checked={checkedFilters.html}
              />
              <StyledLabel htmlFor='html'>
                <HTMLIcon />
              </StyledLabel>
            </FilterWrap>

            <FilterWrap>
              <HiddenCheckbox
                type='checkbox'
                name='filters'
                id='svg'
                value='svg'
                onChange={(event) => onFilterChange(event, 'svg')}
                checked={checkedFilters.svg}
              />
              <StyledLabel htmlFor='svg'>
                <SVGIcon />
              </StyledLabel>
            </FilterWrap>

            <FilterWrap>
              <HiddenCheckbox
                type='checkbox'
                name='filters'
                id='mq'
                value='mq'
                onChange={(event) => onFilterChange(event, 'mq')}
                checked={checkedFilters.mq}
              />
              <StyledLabel htmlFor='mq'>
                <MQIcon />
              </StyledLabel>
            </FilterWrap>

            <FilterWrap>
              <HiddenCheckbox
                type='checkbox'
                name='filters'
                id='angular'
                value='angular'
                onChange={(event) => onFilterChange(event, 'angular')}
                checked={checkedFilters.angular}
              />
              <StyledLabel htmlFor='angular'>
                <AngularIcon />
              </StyledLabel>
            </FilterWrap>

            <FilterWrap>
              <HiddenCheckbox
                type='checkbox'
                name='filters'
                id='react'
                value='react'
                onChange={(event) => onFilterChange(event, 'react')}
                checked={checkedFilters.react}
              />
              <StyledLabel htmlFor='react'>
                <ReactIcon />
              </StyledLabel>
            </FilterWrap>
          </FiltersHolder>

          {renderClearButton()}
        </FiltersContainer>

        <CardsContainer>{renderCards()}</CardsContainer>
      </MainContainer>

      <TooSmallContainer>
        <SmallWrapper>
          <SmallMessage>
            Sorry.. This resolution is too small for this part of website.
            Please switch to larger device.
          </SmallMessage>
          <SmallLink to='/css'>Back</SmallLink>
        </SmallWrapper>
      </TooSmallContainer>
    </StyledRouterPage>
  );
};

/*************************************************  Styles  *************************************************/
const StyledRouterPage = styled(RouterPage)`
  background: ${getColor('section')};
  padding: 0;
  display: flex;
  flex-direction: column;
`;

const StyledHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: ${getVar('appSpacing')};
  padding-top: 6rem;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: ${getVar('appSpacing')} 4.5rem;
    align-items: center;
  }

  @media (max-width: 450px) {
    padding: ${getVar('appSpacing')} 2rem;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;

  &::before {
    position: absolute;
    content: '';
    bottom: -1.5rem;
    left: calc(${getVar('appSpacing')} * (-1));
    width: 12rem;
    height: 0.1rem;
    background: ${getColor('text')};
  }

  @media (max-width: 800px) {
    &::before {
      display: none;
    }
  }
`;

const StyledTitle = styled.h1`
  font-family: ${getFont('heading')};
  font-size: 5rem;
  font-weight: normal;
  line-height: 1;
  margin-left: 2rem;
  margin-right: 5rem;
  color: ${getColor('heading')};

  @media (max-width: 700px) {
    font-size: 4rem;
  }

  @media (max-width: 450px) {
    font-size: 3rem;
    margin-right: 3rem;
  }

  @media (max-width: 350px) {
    margin-right: 2rem;
  }
`;

const StyledTitleLink = styled(NavLink)`
  font-family: ${getFont('heading')};
  font-size: 2.4rem;
  line-height: 1;
  margin-right: 3rem;
  transition: 0.5s;
  color: ${getColor('text', 0.5)};

  &:hover {
    color: ${getColor('text', 1)};
  }

  &:last-of-type {
    margin-right: 0;
  }

  @media (max-width: 350px) {
    margin-right: 2rem;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &::before,
  &::after {
    position: absolute;
    content: '';
    bottom: -1.5rem;
    right: 0;
    width: 100%;
    height: 0.1rem;
    background: ${getColor('text')};
  }

  &::after {
    background: ${getColor('accent')};
    height: 0.2rem;
    width: 0;
    transition: 0.5s;
  }

  &:focus-within::after {
    left: 0;
    width: 100%;
  }

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 3rem;
  }
`;

const SearchInput = styled.input`
  background: none;
  color: ${getColor('text')};
  border: none;
  width: 33rem;
  height: 5rem;
  padding: 0 5rem 0 1rem;
  font-size: 1.8rem;

  &::placeholder {
    color: ${getColor('text', 0.5)};
    font-family: ${getFont('main')};
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 1rem;
  width: 2.6rem;
  height: 2.6rem;
  pointer-events: none;
`;

const StyledXButton = styled.button`
  position: absolute;
  background-color: transparent;
  border: none;
  right: 1rem;
  color: ${getColor('text')};
  transition: 0.5s;

  @media (hover: hover) {
    &:hover {
      color: ${getColor('heading')};
    }
  }

  & > svg {
    width: 1.6rem;
    height: 1.6rem;
  }
`;

const MainContainer = styled.div`
  width: 100%;
  flex: auto;
  display: flex;
  padding: ${getVar('appSpacing')} ${getVar('appSpacing')}
    ${getVar('appSpacing')} 3rem;

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  @media (max-width: 800px) {
    padding: 0 ${getVar('appSpacing')} ${getVar('appSpacing')} 3rem;
  }

  @media (max-width: 450px) {
    padding: 0 1rem 3rem 1rem;
  }
`;

const FiltersContainer = styled.div`
  border-right: 0.1rem solid ${getColor('text')};
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 1100px) {
    flex-direction: row;
    border: none;
  }

  @media (max-width: 450px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0;
  }
`;

const FiltersHolder = styled.div`
  @media (max-width: 1100px) {
    display: flex;
  }

  @media (max-width: 450px) {
    width: 100%;
    justify-content: space-between;
  }
`;

const FilterWrap = styled.div``;

const HiddenCheckbox = styled.input`
  display: none;

  &:checked ~ label {
    color: ${getColor('accent')};
  }
`;

const StyledLabel = styled.label`
  color: ${getColor('text', 0.5)};
  transition: 0.5s;
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (hover: hover) {
    &:hover {
      color: ${getColor('text', 1)};
    }
  }

  & > svg {
    height: 3rem;
    width: 3rem;
  }

  @media (max-width: 700px) {
    & > svg {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  @media (max-width: 555px) {
    width: 5rem;
    height: 5rem;
  }

  @media (max-width: 455px) {
    width: 4rem;
    height: 4rem;

    & > svg {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
`;

const ClearButton = styled.button`
  background-color: transparent;
  color: ${getColor('text', 0.5)};
  border: none;
  transition: 0.5s;
  width: 6rem;
  height: 6rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (hover: hover) {
    &:hover {
      color: ${getColor('text', 1)};
    }
  }

  & > svg {
    height: 3rem;
    width: 3rem;
  }

  @media (max-width: 700px) {
    & > svg {
      height: 2.5rem;
      width: 2.5rem;
    }
  }

  @media (max-width: 555px) {
    width: 5rem;
    height: 5rem;
  }

  @media (max-width: 455px) {
    width: 4rem;
    height: 4rem;

    & > svg {
      height: 2.2rem;
      width: 2.2rem;
    }
  }
`;

const CardsContainer = styled.div`
  display: flex;
  padding: 3rem 1.5rem;
  margin-left: ${getVar('appSpacing')};
  margin-right: 1.5rem;
  overflow-x: auto;
  overflow-y: hidden;
  flex: auto;
  position: relative;

  @media (max-width: 1100px) {
    margin-left: 0;
    padding: 4.5rem 1.5rem 3rem 1.5rem;
  }
`;

const StyledNopeIcon = styled(NopeIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${getColor('text', 0.15)};
  width: 100%;
  height: 100%;
  max-width: 44rem;
  max-height: 44rem;
`;

const TooSmallContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${getColor('section')};
  align-items: center;
  justify-content: center;
  padding: 8rem 3rem 3rem 3rem;
  z-index: 2;
  display: none;

  @media (max-width: 319px) {
    display: flex;
  }
`;

const SmallWrapper = styled.div`
  width: 100%;
  max-width: 60rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SmallMessage = styled.span`
  text-align: center;
  font-size: 1.6rem;
`;

const SmallLink = styled(NavLink)`
  position: relative;
  color: ${getColor('accent')};
  text-transform: uppercase;
  padding: 2rem;
  transition: 0.5s;
  background-color: ${getColor('accent', 0)};
  margin-top: 5rem;
  font-size: 1.6rem;

  @media (hover: hover) {
    &:hover {
      background-color: ${getColor('accent', 0.05)};
    }
  }

  &::before {
    position: absolute;
    content: '';
    width: 3rem;
    height: 0.1rem;
    background-color: ${getColor('accent')};
    top: -2rem;
    left: 50%;
    transform: translateX(-50%);
  }
`;
