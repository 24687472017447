import { SkillsModel } from './skills.model';

export const XP: SkillsModel[] = [
  {
    dateFrom: 'May 5th 2019.',
    dateTo: 'Today',
    position: 'Frontend Developer',
    company: {
      name: 'P3 Group',
      link: 'https://www.linkedin.com/company/p3/',
    },
    skills: [
      'Angular   React',
      'Typescript',
      'AngularMaterial   MaterialUI',
      'Agile workflow ( SCRUM )',
      'Microsoft Azure DevOps ( SCRUM Tool )',
      'Microsoft Azure AD - B2C Customization',
      'Shopware 6 ( CMS and Theme plugins )',
      'Figma / InVision / Zeplin',
      'git / npm / yarn',
      '( RxJS   Redux )',
      // '( ReactNative )',
    ],
    other: [
      {
        text: '* Part of the team in Škoda Hackathon, Prague 2020',
      },
      {
        text: '** Made a contribution to AngularMaterial',
        link: 'https://github.com/angular/components/pull/17576',
      },
    ],
  },
  {
    dateFrom: 'June 17th 2018.',
    dateTo: 'March 22nd 2019.',
    position: 'Designer',
    company: {
      name: 'Freelance',
    },
    skills: [
      'UX / UI Design',
      'Adobe Illustrator',
      'Adobe Photoshop',
      'Adobe XD',
    ],
  },
  {
    dateTo: '( Ongoing / Paused )',
    dateFrom: 'October 1st 2017.',
    position: 'Student',
    company: {
      name: 'ICT College of Vocational Studies, Belgrade',
      link: 'https://ict.edu.rs/',
    },
    skills: [
      'HTML5',
      'CSS3   SASS   SCSS   less   BEM',
      'JavaScript',
      'jQuery',
      'Bootstrap',
      'Responsive design',
      '( xml   SQL   C / C++ / C# ) ',
    ],
  },
];
