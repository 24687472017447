import React from 'react';

export const ImportantIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='6.789'
      height='30'
      viewBox='0 0 6.789 30'
      className={props.className}
    >
      <path
        id='important'
        d='M1.89,7.1H8.17L7.109,26.959h-4.2Zm3.14,30a3.376,3.376,0,0,1-2.461-.955,3.164,3.164,0,0,1-.976-2.355,3.1,3.1,0,0,1,.976-2.334,3.418,3.418,0,0,1,2.461-.934,3.291,3.291,0,0,1,2.4.934,3.13,3.13,0,0,1,.955,2.334,3.2,3.2,0,0,1-.955,2.355A3.251,3.251,0,0,1,5.03,37.1Z'
        transform='translate(-1.593 -7.1)'
        fill='currentColor'
      />
    </svg>
  );
};
