/*************************************************  Imports  *************************************************/
import React from 'react';
import styled from '@emotion/styled';

import { getColor } from '../../assets/style/colors/colors';

import { LogoIcon } from '../../assets/icon-components/LogoIcon';
import { getVar } from '../../assets/style/variables/variables';

/*************************************************  Component  *************************************************/
export const Loader = () => {
  /*************************************************  Template  *************************************************/
  return (
    <LoaderContainer className='loader'>
      <LoaderInner>
        <LogoContainer>
          <LogoIcon />
        </LogoContainer>
        <StyledParagraph>Preparing spells</StyledParagraph>
        <StyledLoadingBar className='loader-bar' />
      </LoaderInner>
    </LoaderContainer>
  );
};

/*************************************************  Style  *************************************************/
const LoaderContainer = styled.div`
  position: fixed;
  left: -100%;
  top: 0;
  display: flex;
  width: calc(100% - ${getVar('sidebarWidth')});
  height: 100%;
  background: ${getColor('nav')};
  z-index: 5;
  opacity: 0;

  @media (max-width: 855px), (max-height: 701px) {
    z-index: 999;
  }
`;

const LoaderInner = styled.div`
  margin: auto;

  @media (max-width: 450px) {
    width: calc(100% - (${getVar('appSpacing')} * 2));
  }
`;

const LogoContainer = styled.div`
  width: 17rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  color: ${getColor('text')};

  & > svg {
    max-width: 19rem;
    max-height: 14.5rem;
    width: 19rem;
    height: 14.5rem;
    transition: 0.5s;
  }

  @media (hover: hover) {
    &:hover {
      color: ${getColor('accent')};

      & > svg {
        filter: drop-shadow(0 0 1rem ${getColor('accent', 0.5)});
      }
    }
  }

  @media (max-width: 800px), (max-height: 600px) {
    width: 15rem;
  }
`;

const StyledParagraph = styled.p`
  text-align: center;
  color: ${getColor('heading')};
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 200;

  @media (max-width: 800px), (max-height: 600px) {
    margin-top: 1.5rem;
    font-size: 1.4rem;
  }
`;

const StyledLoadingBar = styled.div`
  width: 45rem;
  height: 0.1rem;
  background-color: ${getColor('text', 0.5)};
  position: relative;

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 0%;
    height: 0.2rem;
    background-color: ${getColor('accent')};
  }

  @media (max-width: 800px), (max-height: 600px) {
    width: 40rem;
  }

  @media (max-width: 650px) {
    width: 30rem;
  }

  @media (max-width: 450px) {
    width: 100%;
  }
`;
