import { CSSCardModel } from '../../CSSCard/css-card.model';

export const cssLinks: CSSCardModel[] = [
  {
    title: 'Sticky footer',
    linkTo: 'https://css-tricks.com/couple-takes-sticky-footer/',
    content: {
      __html: `Cool article on how to make footer that stick to the bottom of the page.<br/><br/>( Flex solution kinda worked the best )`,
    },
    filters: ['css'],
  },
  {
    title: 'Textbox leading trim',
    linkTo:
      'https://medium.com/microsoft-design/leading-trim-the-future-of-digital-typesetting-d082d84b202',
    content: {
      __html: `29. Aug 2020.<br/><br/><br/>This is an article which explains 2 upcoming CSS properties:<br/><br/><code>text-edge<br/>leading-trim</code><br/><br/>They are still WIP, but if they release them it will be soooo great. :D<br/><br/>In short, these 2 properties will remove the 'excess' spacing around text which is added by 'font-size' and 'line-heigh'.`,
    },
    filters: ['important', 'css'],
  },
  {
    title: 'GRID: Complete guide',
    linkTo: 'https://css-tricks.com/snippets/css/complete-guide-grid/',
    content: {
      __html: `Fantastic article about CSS grid.<br/><br/>Literally everything you need to know if you want to master 'grid'.`,
    },
    filters: ['css'],
  },
  {
    title: 'Scrollable container with dynamic height',
    linkTo:
      'https://medium.com/@stephenbunch/how-to-make-a-scrollable-container-with-dynamic-height-using-flexbox-5914a26ae336',
    content: {
      __html: `I found this article very helpful a couple of times on some project, so it deserved its place here. :D`,
    },
    filters: ['css'],
  },
  {
    title: ':has() selector',
    linkTo: 'https://developer.mozilla.org/en-US/docs/Web/CSS/:has',
    content: {
      __html: `This is probably one of my favorite and most anticipated selectors ever. It is still not supported, but when it gets here (and it will), it will be revolutionary.<br/><br/> As of right now, we don't have an option to target an element which containes specific element , but this selector will make that possible. :D<br/><br/>This selector is going to be worth all this waiting.<br/><br/><br/><a class='link' href='https://caniuse.com/#search=%3Ahas' >Link: Can I Use</a>`,
    },
    filters: ['important', 'css'],
  },
  {
    title: 'Variable fonts',
    linkTo: 'https://v-fonts.com/',
    content: {
      __html: `<a class='link' href='https://design.tutsplus.com/articles/what-are-variable-fonts--cms-31310'>What are variable fonts</a><br/><br/><a class='link' href='https://web.dev/variable-fonts/'>Introduction to variable fonts on web</a><br/><br/><a class='link' href='https://web.dev/variable-fonts/'>Variable fonts guide</a><br/><br/><a class='link' href='https://css-tricks.com/getting-the-most-out-of-variable-fonts-on-google-fonts/'>Getting most out of variable fonts</a><br/><br/>`,
    },
    filters: ['css'],
  },
  {
    title: 'CUBE CSS',
    linkTo: 'https://piccalil.li/blog/cube-css/',
    content: {
      __html: `CUBE is very interesting CSS methodology.<br/><br/>I have tried to work with it on a couple of personal projects and it's fun,but I still don't quite understand it completely.<br/><br/>Nevertheless I think it's a great article to read and maybe give it a shot.`,
    },
    filters: ['css'],
  },
  {
    title: 'CSS Logical Properties',
    linkTo: 'https://piccalil.li/tutorial/css-logical-properties/',
    content: {
      __html: `Great article about CSS Logical Properties and how to use them with RTL layouts.<br/><br/>At the bottom of the article there are more resources on this topic which are worth looking at.<br/><br/><br/><a class='link' href='https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Logical_Properties'>What are CSS Logical Properties</a>`,
    },
    filters: ['important', 'css'],
  },
  {
    title: 'Media Queries 1o1',
    linkTo: 'https://css-tricks.com/a-complete-guide-to-css-media-queries/',
    content: {
      __html: `All there is to know about CSS media queries.`,
    },
    filters: ['important', 'mq', 'css'],
  },
];
