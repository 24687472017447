import React from 'react';

export const SectionDownIcon = () => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 37.503'>
      <path
        id='section-down'
        d='M320,899.026,0,866.345v-4.822H320Z'
        transform='translate(0 -861.523)'
        fill='currentColor'
      />
    </svg>
  );
};
