// ******************************************************  TYPE  ****************************************************** //
export type UIVar = keyof typeof uiVariables;

// ******************************************************  FUNCTIONS  ****************************************************** //
export function getVar(v: UIVar) {
  return `var(${uiVariables[v]})`;
}

// ******************************************************  VARIABLES  ****************************************************** //
const uiVariables = {
  sidebarWidth: '--sidebar-width',
  lineHeight: '--line-height',
  appSpacing: '--app-spacing',
  itemSpacing: '--item-spacing',
};
