import React from 'react';

import { CSSForm, CSSFormProps } from '../CSSForm';
import { cssNotes } from './data/notes.data';

export const NotesPage = () => {
  const props: CSSFormProps = {
    title: 'Notes',
    links: [
      {
        text: 'Links',
        linkTo: '/css/links',
      },
      {
        text: 'Blog',
        linkTo: '/css/blog',
      },
    ],
    data: cssNotes,
  };

  return <CSSForm title={props.title} links={props.links} data={props.data} />;
};
