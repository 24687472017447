/*************************************************  Imports  *************************************************/
import { BlogPostModel } from '../models/blog-post.model';

/*************************************************  Markdown Files  *************************************************/
import ProperFontsImportMarkdown from '../posts/proper-fonts-import.md';
import DynamicAngularThemingMarkdown from '../posts/angular-dynamic-theming.md';
import CheckSafariMarkdown from '../posts/check-safari.md';
import DynamicReactThemingMarkdown from '../posts/react-dynamic-theming.md';

/*************************************************  Export  *************************************************/
export const BlogPosts: BlogPostModel[] = [
  {
    title:
      'Dynamic theming for Angular with CSS variables ( + Angular Material Support )',
    linkTo: 'angular-dynamic-theming-with-css-variables',
    date: '02. Aug 2021',
    markdown: DynamicAngularThemingMarkdown,
  },
  {
    title: 'Check if Browser is Safari (iOS or MacOS)',
    linkTo: 'check-if-browser-is-safari',
    date: '30. Jul 2021',
    markdown: CheckSafariMarkdown,
  },
  {
    title: 'Proper way to import fonts into CSS',
    linkTo: 'proper-fonts-import',
    date: '25. Jul 2021',
    markdown: ProperFontsImportMarkdown,
  },
  {
    title:
      'Design system for React using TypeScript, Styled Components and CSS variables',
    linkTo: 'design-system-react-ts-css',
    date: '27. Aug 2020',
    markdown: DynamicReactThemingMarkdown,
  },
];
