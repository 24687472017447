import React from 'react';

export const SkillsIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.982'
      height='19.427'
      viewBox='0 0 20.982 19.427'
    >
      <path
        id='skills'
        d='M17.7,22.3H14.176V21.233a2.255,2.255,0,0,0-2.252-2.252H9.058a2.255,2.255,0,0,0-2.252,2.252V22.3H3.278A3.282,3.282,0,0,0,0,25.58v9.549a3.282,3.282,0,0,0,3.278,3.278H17.7a3.282,3.282,0,0,0,3.278-3.278.82.82,0,0,0-1.639,0A1.641,1.641,0,0,1,17.7,36.768H3.278a1.641,1.641,0,0,1-1.639-1.639V29.308H9.713v1.231a.82.82,0,1,0,1.639,0V29.308h7.991V30.99a.82.82,0,0,0,1.639,0V25.58A3.282,3.282,0,0,0,17.7,22.3ZM8.445,21.233a.614.614,0,0,1,.613-.613h2.866a.614.614,0,0,1,.613.613V22.3H8.445V21.233ZM1.639,27.669V25.581a1.641,1.641,0,0,1,1.639-1.639H17.7a1.641,1.641,0,0,1,1.639,1.639v2.089Z'
        transform='translate(0 -18.981)'
        fill='currentColor'
      />
    </svg>
  );
};
