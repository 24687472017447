import React from 'react';

export const CSSIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='26.72'
      height='30'
      viewBox='0 0 26.72 30'
      className={props.className}
    >
      <path
        id='css'
        d='M678.522,1285.666h26.72l-.371,5.344-12.857,5.291h12.275l-1.376,16.3-10.635,3.069-11.111-3.069-.688-8.359h5.4l.37,4.18,5.767,1.481,5.926-1.693.423-6.772H680.056l-.423-5.133,12.645-5.291H679.263Z'
        transform='translate(-678.522 -1285.666)'
        fill='currentColor'
      />
    </svg>
  );
};
