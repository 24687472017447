import React from 'react';

export const TriangleIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 40 35'
    >
      <g id='triangle' transform='translate(40 35) rotate(180)' fill='none'>
        <path d='M20,0,40,35H0Z' stroke='none' />
        <path
          d='M 20 4.031137466430664 L 3.446365356445312 33 L 36.55363464355469 33 L 20 4.031137466430664 M 20 0 L 40 35 L 0 35 L 20 0 Z'
          stroke='none'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};
