import React from 'react';

export const CVIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.244'
      height='10.136'
      viewBox='0 0 20.244 10.136'
    >
      <path
        id='cv'
        d='M5.852.168A5.629,5.629,0,0,1,3.143-.483a4.858,4.858,0,0,1-1.9-1.806A4.976,4.976,0,0,1,.546-4.9a4.976,4.976,0,0,1,.693-2.611,4.858,4.858,0,0,1,1.9-1.806,5.658,5.658,0,0,1,2.723-.651,5.712,5.712,0,0,1,2.3.448A4.613,4.613,0,0,1,9.9-8.232L8.442-6.888A3.131,3.131,0,0,0,5.978-8.036a3.272,3.272,0,0,0-1.624.4A2.836,2.836,0,0,0,3.241-6.524a3.272,3.272,0,0,0-.4,1.624,3.272,3.272,0,0,0,.4,1.624A2.836,2.836,0,0,0,4.354-2.163a3.272,3.272,0,0,0,1.624.4A3.115,3.115,0,0,0,8.442-2.926L9.9-1.582A4.538,4.538,0,0,1,8.162-.28,5.762,5.762,0,0,1,5.852.168ZM20.79-9.8,16.548,0h-2.24L10.08-9.8h2.45l2.982,7,3.024-7Z'
        transform='translate(-0.546 9.968)'
        fill='currentColor'
      />
    </svg>
  );
};
