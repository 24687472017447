import { keyframes } from '@emotion/core';

// ******************** HOME PAGE ******************** //
// Image
export const fadeInFwd = keyframes`
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

//Bottom line
export const slideInTop = keyframes`
0% {
    transform: translateY(calc(var(--line-height) * -1));
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
`;

//Bottom Triangle
export const slideInTopAbsolute = keyframes`
0% {
    transform: translateY(calc(var(--line-height) * -1)) translateX(-50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0) translateX(-50%);
    opacity: 1;
  }
`;

// Section container
export const slideInLeft = keyframes`
0% {
    transform: translateX(-200%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Main heading
export const slideInLeftHeading = keyframes`
0% {
    transform: translateX(-10rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Image line
export const slideInRightLine = keyframes`
0% {
    transform: translateX(20rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Main text
export const scaleInTopText = keyframes`
  0% {
    transform: scaleY(0);
    transform-origin: 100% 0%;
    opacity: 0;
  }
  100% {
    transform: scaleY(1);
    transform-origin: 100% 0%;
    opacity: 1;
  }
`;

// ******************** SKILLS PAGE ******************** //
//Vertical lines
export const height100 = keyframes`
  0%{
    height: 0;
  }
  100%{
    height: 100%;
  }
`;

//Horizontal lines
export const timelineHorizontal = keyframes`
  0%{
    width: 0;
  }
  100%{
    width: 50%;
  }
`;

// Skills left
export const slideInLeftSkill = keyframes`
0% {
    transform: translateX(-20rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

//Skills right
export const slideInRightSkill = keyframes`
0% {
    transform: translateX(20rem);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

// ******************** CSS PAGE ******************** //
// Section container
export const slideInRight = keyframes`
0% {
    transform: translateX(200%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const cssAccentLine = keyframes`
  0%{
    transform: translateX(-50%) scale(0);
  }
  100% {
    transform: translateX(-50%) scale(1);
  }
`;
