/*************************************************  Imports  *************************************************/
import React, { useState, useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { getColor, setThemeColor } from '../../../assets/style/colors/colors';
import { getVar } from '../../../assets/style/variables/variables';

import { SunIcon } from '../../../assets/icon-components/SunIcon';
import { MoonIcon } from '../../../assets/icon-components/MoonIcon';

/*************************************************  Component  *************************************************/
export const ThemeButton = ({ style }: any) => {
  /*************************************************  Hooks  *************************************************/
  const [isLightTheme, setIsLightTheme] = useState(false);
  const isFirstLoad = useRef(true);

  useEffect(() => {
    if (isFirstLoad.current) {
      isFirstLoad.current = false;

      const themePref = localStorage.getItem('themePref');

      if (themePref && themePref === 'light') {
        setIsLightTheme(true);
        setThemeColor('light');
      } else {
        setIsLightTheme(false);
        setThemeColor('dark');
      }
    }
  }, [isLightTheme]);

  /*************************************************  Methods  *************************************************/
  function getThemeButton() {
    return isLightTheme ? (
      <StyledThemeButton
        style={style}
        onClick={() => switchTheme(false)}
        title='Light mode'
      >
        <SunIcon />
      </StyledThemeButton>
    ) : (
      <StyledThemeButton
        style={style}
        onClick={() => switchTheme(true)}
        title='Dark mode'
      >
        <MoonIcon />
      </StyledThemeButton>
    );
  }

  function switchTheme(isLight: boolean) {
    setIsLightTheme(isLight);

    if (isLightTheme) {
      setThemeColor('dark');
      localStorage.setItem('themePref', 'dark');
    } else {
      setThemeColor('light');
      localStorage.setItem('themePref', 'light');
    }
  }

  /*************************************************  Template  *************************************************/
  return getThemeButton();
};

/*************************************************  Styles  *************************************************/
const StyledThemeButton = styled.button`
  width: 100%;
  height: ${getVar('sidebarWidth')};
  color: ${getColor('accent')};
  background-color: transparent;
  border: none;
  border-top: 0.1rem solid ${getColor('text', 0.2)};
  cursor: pointer;
  transition: 0.5s;
  display: flex;
  align-items: center;
  justify-content: center;

  & > svg {
    min-width: 2rem;
    max-width: 2.4rem;
    min-height: 2rem;
    max-height: 2.4rem;
  }

  @media (hover: hover) {
    &:hover {
      background-color: ${getColor('text', 0.1)};
    }
  }

  @media (max-width: 855px), (max-height: 701px) {
    position: absolute;
    width: 4.5rem;
    height: 4.5rem;
    top: 2rem;
    left: 2rem;
    border: none;
    transition: 0.2s;

    svg {
      width: 2.4rem;
      height: 2.4rem;
    }
  }
`;
