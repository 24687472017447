import React from 'react';

export const MailIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.834'
      height='15.91'
      viewBox='0 0 20.834 15.91'
    >
      <path
        id='mail'
        d='M20.02,69.9a.814.814,0,0,0,.814-.814V63.755A3.259,3.259,0,0,0,17.579,60.5H3.255A3.259,3.259,0,0,0,0,63.755v9.4A3.259,3.259,0,0,0,3.255,76.41H17.579a3.259,3.259,0,0,0,3.255-3.255.814.814,0,0,0-1.628,0,1.63,1.63,0,0,1-1.628,1.628H3.255a1.63,1.63,0,0,1-1.628-1.628V63.928l7.07,4.4a3.235,3.235,0,0,0,3.438,0l7.07-4.4v5.158A.814.814,0,0,0,20.02,69.9Zm-8.744-2.958a1.618,1.618,0,0,1-1.719,0L2.307,62.433a1.619,1.619,0,0,1,.949-.306H17.579a1.619,1.619,0,0,1,.949.306Z'
        transform='translate(0 -60.5)'
        fill='currentColor'
      />
    </svg>
  );
};
