/*************************************************  Imports  *************************************************/
import React from 'react';
import styled from '@emotion/styled';
import { getVar } from '../../assets/style/variables/variables';

/*************************************************  Component  *************************************************/
export const RouterPage = ({ className, children }: any) => {
  /*************************************************  Template  *************************************************/
  return (
    <RouterPageContainer className={className}>{children}</RouterPageContainer>
  );
};

/*************************************************  Style  *************************************************/
const RouterPageContainer = styled.div`
  width: calc(100% - ${getVar('sidebarWidth')});
  overflow-x: hidden;
  overflow-y: auto;
  padding: ${getVar('appSpacing')};
  transition: 0.5s;
  //! Required position absolute for good transition animation effect
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
