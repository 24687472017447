import React from 'react';

export const LinkedIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.631'
      height='20.753'
      viewBox='0 0 20.631 20.753'
    >
      <path
        id='ln'
        d='M6.827,24.171H4.99a1.224,1.224,0,0,1-1.224-1.224V11.56A1.224,1.224,0,0,1,4.99,10.336H6.827A1.224,1.224,0,0,1,8.051,11.56V22.947A1.224,1.224,0,0,1,6.827,24.171ZM8.479,5.928A2.51,2.51,0,1,0,5.97,8.438,2.51,2.51,0,0,0,8.479,5.928ZM24.09,22.947V16.56c0-3.714-.785-6.469-5.123-6.469a4.447,4.447,0,0,0-4.056,2.127h0V11.56a1.224,1.224,0,0,0-1.224-1.224H11.969a1.224,1.224,0,0,0-1.224,1.224V22.947a1.224,1.224,0,0,0,1.224,1.224h1.714a1.224,1.224,0,0,0,1.224-1.224V17.3c0-1.8.458-3.542,2.688-3.542,2.2,0,2.271,2.057,2.271,3.656v5.531a1.224,1.224,0,0,0,1.224,1.224h1.775a1.224,1.224,0,0,0,1.224-1.224Z'
        transform='translate(-3.459 -3.418)'
        fill='currentColor'
      />
    </svg>
  );
};
