/*************************************************  Imports  *************************************************/
import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import $ from 'jquery';

import { getColor } from '../../../assets/style/colors/colors';

/*************************************************  Component  *************************************************/
export const CustomCursor = () => {
  /*************************************************  Logic  *************************************************/
  useEffect(() => {
    const cursorDot = $('.cursor-dot');
    const cursor = $('.cursor-lag');

    // Coordinates
    $(document).mousemove((e) => {
      cursorDot?.css({
        top: `${e.pageY}px`,
        left: `${e.pageX}px`,
      });

      cursor?.css({
        top: `${e.pageY}px`,
        left: `${e.pageX}px`,
      });
    });

    //Show and hide when cursor is in/out of the window
    $(document).mouseenter(() => {
      cursorDot?.css('display', 'block');
      cursor?.css('display', 'block');
    });

    $(document).mouseleave(() => {
      cursorDot?.css('display', 'none');
      cursor?.css('display', 'none');
    });

    //Click animation
    $(document).mousedown(() => {
      cursorDot.removeClass('clicked');
    });

    $(document).mouseup(() => {
      cursorDot.addClass('clicked');
    });

    // Hover state when on clickable element
    $('a[href]').hover(
      () => {
        cursor?.addClass('hovering');
      },
      () => {
        cursor?.removeClass('hovering');
      }
    );

    $('button').hover(
      () => {
        cursor?.addClass('hovering');
      },
      () => {
        cursor?.removeClass('hovering');
      }
    );
  });

  /*************************************************  Template  *************************************************/
  return (
    <>
      <CursorDot className='cursor-dot'></CursorDot>
      <CursorLag className='cursor-lag'></CursorLag>
    </>
  );
};

/*************************************************  Style  *************************************************/
const CursorDot = styled.div`
  position: fixed;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: ${getColor('accent')};
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  top: -0.8rem;
  left: -0.8rem;

  @media (hover: none) {
    display: none !important;
  }

  &::after {
    position: absolute;
    content: '';
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 1rem solid ${getColor('accent', 0)};
    top: 50%;
    left: 50%;
    opacity: 1;
    transform: translate(-50%, -50%);
  }

  &.clicked {
    &::after {
      width: 6rem;
      height: 6rem;
      opacity: 0;
      border: 3rem solid ${getColor('accent', 0.1)};
      transition: width 0.4s, height 0.4s, opacity 0.4s, border-width 0.4s;
    }
  }
`;

const CursorLag = styled.div`
  position: fixed;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  border: 1px solid ${getColor('text', 0.3)};
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: width 0.2s, height 0.2s, border-color 0.2s;
  top: -4rem;
  left: -4rem;
  z-index: 9999;
  transition: width 0.2s, height 0.2s, border-color 0.2s, top 0.1s ease-out,
    left 0.1s ease-out;

  @media (hover: none) {
    display: none !important;
  }

  &.hovering {
    width: 6rem;
    height: 6rem;
    border-color: ${getColor('text', 0.6)};
  }
`;
