import React from 'react';

export const SVGIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      className={props.className}
    >
      <path
        id='svg'
        d='M6.7,15.065h5.61L8.33,11.09a2.753,2.753,0,1,1,2.76-2.76l3.975,3.975V6.7a2.761,2.761,0,0,1,0-3.885,2.716,2.716,0,0,1,3.87,0,2.761,2.761,0,0,1,0,3.885v5.61l3.99-3.975a2.745,2.745,0,1,1,2.745,2.76l-3.975,3.975h5.61a2.761,2.761,0,0,1,3.885,0,2.716,2.716,0,0,1,0,3.87,2.761,2.761,0,0,1-3.885,0h-5.61l3.975,3.99a2.745,2.745,0,1,1-2.745,2.745l-3.99-3.975v5.61a2.761,2.761,0,0,1,0,3.885,2.716,2.716,0,0,1-3.87,0,2.761,2.761,0,0,1,0-3.885v-5.61L11.09,25.67a2.753,2.753,0,1,1-2.76-2.745l3.975-3.99H6.7a2.761,2.761,0,0,1-3.885,0,2.716,2.716,0,0,1,0-3.87A2.761,2.761,0,0,1,6.7,15.065Z'
        transform='translate(-2 -2)'
        fill='currentColor'
      />
    </svg>
  );
};
