import React from 'react';

export const SearchIcon = (props: { className?: string }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='25.734'
      height='26.622'
      viewBox='0 0 25.734 26.622'
      className={props.className}
    >
      <path
        id='Path_14'
        data-name='Path 14'
        d='M22.358,23.893l-7.717-7.716-.906.792a8.618,8.618,0,0,1-9.782-.048.478.478,0,0,1-.123-.648.438.438,0,0,1,.622-.129,7.772,7.772,0,0,0,10.077-1.039,8.551,8.551,0,0,0,0-11.745,7.778,7.778,0,0,0-11.293,0,8.606,8.606,0,0,0-1.1,10.324A.477.477,0,0,1,2,14.33a.439.439,0,0,1-.619-.143A9.567,9.567,0,0,1,.047,8.274,9.414,9.414,0,0,1,2.6,2.7a8.653,8.653,0,0,1,12.562,0,9.532,9.532,0,0,1,1.159,11.58l-1.14,1.4,7.692,7.691a.365.365,0,1,1-.516.516Z'
        transform='translate(0 2.8) rotate(-7)'
        fill='currentColor'
      />
    </svg>
  );
};
