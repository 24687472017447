/*************************************************  Imports  *************************************************/
import React, { useState, CSSProperties, useEffect } from 'react';
import styled from '@emotion/styled';

import { getColor } from '../../../assets/style/colors/colors';
import { getVar } from '../../../assets/style/variables/variables';
import { useWindowDimensions } from '../../hooks/DimensionsHook';

import { ThemeButton } from './ThemeButton';
import { SocialLinks } from './SocialLinks';
import { SidebarRouterLinks } from './SidebarRouterLinks';
import { useLocation } from 'react-router-dom';

/*************************************************  Component  *************************************************/
export const Sidebar = () => {
  /*************************************************  Hooks  *************************************************/
  const { width, height } = useWindowDimensions();

  /******************************** Sidebar mobile logic and styles ********************************/
  const ShowSidebarStyle: CSSProperties = {
    position: 'fixed',
    top: '0',
    left: '0',
  };

  const HideSidebarStyle: CSSProperties = {
    position: 'fixed',
    top: '0',
    left: '-100%',
  };

  const [mobileStyles, setMobileStyles] = useState(HideSidebarStyle);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      setMobileStyles({
        position: 'fixed',
        top: '0',
        left: '-100%',
      });
      setSidebarVisible(false);
    }, 1000);
  }, [location]);

  function toggleSidebar() {
    if (sidebarVisible) {
      setMobileStyles(HideSidebarStyle);
      setSidebarVisible(false);
    } else {
      setMobileStyles(ShowSidebarStyle);
      setSidebarVisible(true);
    }
  }

  function getMenuButton() {
    return (
      <MenuButton onClick={() => toggleSidebar()}>
        <MenuButtonLines className={sidebarVisible ? 'opened' : ''} />
      </MenuButton>
    );
  }

  /*************************************************  Template  *************************************************/
  return (
    <>
      {width <= 855 || height <= 701 ? getMenuButton() : ''}
      <SidebarContainer
        style={width <= 855 || height <= 701 ? mobileStyles : {}}
      >
        <SidebarContent>
          <SidebarRouterLinks />
          <SocialLinks />
        </SidebarContent>
        <ThemeButton
          style={
            sidebarVisible
              ? { position: 'fixed', left: 'calc(100% - 6.5rem)' }
              : {}
          }
        />
      </SidebarContainer>
    </>
  );
};

/*************************************************  Styles  *************************************************/
const MenuButton = styled.button`
  position: fixed;
  z-index: 99;
  top: calc(var(--app-spacing) - 2.25rem + 0.7rem);
  left: calc(var(--app-spacing) - 0.75rem);
  height: 4.5rem;
  width: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;

  @media (hover: hover) {
    &:hover > div::after {
      width: 100%;
    }
  }
`;

const MenuButtonLines = styled.div`
  position: relative;
  width: ${getVar('appSpacing')};
  height: 1rem;

  &::before,
  &::after {
    position: absolute;
    content: '';
    height: 0.2rem;
    border-radius: 0.4rem;
    background-color: ${getColor('heading')};
    left: 0;
    transition: 0.5s;
  }

  &::before {
    top: 0;
    width: 100%;
  }

  &::after {
    bottom: 0;
    width: 70%;
  }

  &.opened {
    &::before {
      top: calc(${getVar('appSpacing')} / 4);
      transform: rotate(45deg);
    }

    &::after {
      width: 100%;
      transform: rotate(-45deg);
    }
  }
`;

const SidebarContainer = styled.nav`
  height: 100%;
  min-width: ${getVar('sidebarWidth')};
  max-width: ${getVar('sidebarWidth')};
  background-color: ${getColor('nav')};
  color: ${getColor('text')};
  font-size: 1.7rem;
  position: relative;
  z-index: 6;
  transition: 0.2s;

  @media (max-width: 855px), (max-height: 701px) {
    min-width: unset;
    max-width: unset;
    width: 100%;
    padding: ${getVar('appSpacing')};
    display: flex;
    align-items: center;
  }

  @media (max-height: 565px) {
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

const SidebarContent = styled.div`
  height: calc(100% - ${getVar('sidebarWidth')});
  display: flex;
  flex-direction: column;

  @media (max-width: 855px), (max-height: 701px) {
    width: 100%;
    height: unset;
  }

  @media (max-height: 565px) {
    height: 100%;
  }
`;
