import React from 'react';

export const SunIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20.83'
      height='20.83'
      viewBox='0 0 20.83 20.83'
    >
      <path
        id='sun'
        d='M20.83,10.415a.814.814,0,0,1-.814.814H19.2a.814.814,0,0,1,0-1.627h.814A.814.814,0,0,1,20.83,10.415ZM10.415,3.255a.814.814,0,0,0,.814-.814V.814A.814.814,0,0,0,9.6.814V2.441A.814.814,0,0,0,10.415,3.255Zm-7.974,7.16A.814.814,0,0,0,1.627,9.6H.814a.814.814,0,0,0,0,1.627h.814A.814.814,0,0,0,2.441,10.415Zm7.974,7.16a.814.814,0,0,0-.814.814v1.627a.814.814,0,0,0,1.627,0V18.389A.814.814,0,0,0,10.415,17.575ZM1.8,6.105l1.389.848a.814.814,0,0,0,.848-1.389L2.645,4.716A.814.814,0,0,0,1.8,6.105Zm17.236,8.619-1.389-.848a.814.814,0,0,0-.848,1.389l1.389.848a.814.814,0,0,0,.848-1.389Zm-3.516,2.891a.814.814,0,1,0-1.409.814l.407.7a.814.814,0,1,0,1.409-.814Zm-.939-14A.813.813,0,0,0,15.7,3.345l.425-.694a.814.814,0,0,0-1.388-.85l-.425.694A.814.814,0,0,0,14.578,3.614Zm-9.262-.4A.814.814,0,0,0,6.725,2.4l-.407-.7a.814.814,0,1,0-1.409.814Zm.922,14a.814.814,0,0,0-1.119.267l-.426.693a.814.814,0,1,0,1.386.852l.426-.693A.814.814,0,0,0,6.238,17.208Zm11.063-10a.81.81,0,0,0,.41-.111l1.405-.821A.814.814,0,0,0,18.3,4.871l-1.405.821A.814.814,0,0,0,17.3,7.208ZM3.119,13.733l-1.405.821a.814.814,0,1,0,.821,1.405l1.405-.821a.814.814,0,0,0-.821-1.405Zm7.3-9.665A6.347,6.347,0,1,0,12.69,16.341a.814.814,0,0,0-.584-1.519,4.715,4.715,0,1,1,2.641-2.534.814.814,0,0,0,1.493.647,6.35,6.35,0,0,0-5.826-8.867Z'
        fill='currentColor'
      />
    </svg>
  );
};
